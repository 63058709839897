import React, { ReactNode, useEffect, useRef } from 'react'

type ClickOutsideProps = {
    exclude: string[] // id of button that open the modal
    onPress: () => void
    children: ReactNode
}

const ClickOutside = ({ onPress, children, exclude }: ClickOutsideProps) => {

    const divRef = useRef<HTMLDivElement>(null);
    const clickListenerRef = useRef<any>(null); // Ref to store the event listener function


    useEffect(() => {
        // Create a function to handle clicks
        const handleClick = (event: any) => {

            // when you click to open component, it closes always because detects the first click as "click outside", this code avoid that
            let firtstClick = false
            exclude.forEach((id: string) => {
                const element = document.getElementById(id)
                if(element && element.contains(event.target)){
                    firtstClick = true
                }
            })
            if(firtstClick)
                return

            if (divRef.current && divRef.current.contains(event.target)) {
                //alert("General:inside")
            } else {
                onPress()
            }
        };
    
        // Assign the listener function to the ref
        clickListenerRef.current = handleClick;
    
        // Add the click event listener to the document body
        document.body.addEventListener('click', clickListenerRef.current);
    
        // Cleanup function to remove the listener on unmount
        return () => {
          document.body.removeEventListener('click', clickListenerRef.current);
        };
      }, []); // Empty dependency array to run only once on mount
  
    return (
        <div ref={divRef} id={"click-outside"}>
            {children}
        </div>
    )
  }
export default React.memo( ClickOutside )