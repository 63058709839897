import React from 'react'
import "./ContainerOfferCards.css"
import OfferCards from '../OfferCard/OfferCard'

type ContainerOfferCardsProps = {
    ids: string[]
}

const ContainerOfferCards = ({ ids }: ContainerOfferCardsProps) => {
    return (
        <div className="con-offer-cards flex">
        {
          ids.map((idOffer) => {
              return (
                <OfferCards id={idOffer} />
              )
          })
        }
        </div>
    )
}

export default React.memo( ContainerOfferCards )