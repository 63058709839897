import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import ArrowColumn from '../../UIcomponents/ArrosColumn/ArrowColumn';
import { TypeOffers, orderHistoryReducer } from '../../../redux/slices/components/MyHistory/myHistoryDataSlice';
import { v4 as uuidv4 } from 'uuid';
import "./HeaderTableMyHistory.css"
import "../MyHistory.css"
import { UserReportType } from '../../../redux/slices/components/ManageReports/manageReportsDataSlice';
import { MessageProps, MessageType, onSendMessage } from '../../../utils/chats/ChatFunctions';
import { Message } from '../../../utils/dbModels';

const HeaderTableMyHistory = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const orderDirection = useSelector((state: RootState) => state.myHistoryData.orderDirection)
    const orderColumn = useSelector((state: RootState) => state.myHistoryData.orderColumn)
    const type: TypeOffers = useSelector((state: RootState) => state.myHistoryData.indexTab) === 1 ? "Job" : "Task"

    const orderArray = useCallback(
            ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => {  
                dispatch(orderHistoryReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
            },
            [],
    )
/*
    const onSendPress = useCallback(
        () => {
            //const messageContent = message;
            //setMessage('');
            const newId = uuidv4()
            const newMessageRequestBy: MessageProps = {
                id: newId,
                content: "show",
                userID: "d7e15ccf-879f-4051-a9eb-164afa9c6ffe",
                chatRoomID: "16dde827-a2c3-4e87-b513-5bf480776c78_d7e15ccf-879f-4051-a9eb-164afa9c6ffe",
                otherUserID: "d7098386-8ca3-4db3-86f9-5f1008014846",
                viewed: false,
                type: MessageType.MESSAGE,
                information: { transactionId: '' },
            };
    
            const newMessagePersonInvolved: MessageProps = {
                id: newId,
                content: "jaja person involved",
                userID: "dd9a7c7c-e612-4d90-9d82-76ae71a59ada",
                chatRoomID: "16dde827-a2c3-4e87-b513-5bf480776c78_dd9a7c7c-e612-4d90-9d82-76ae71a59ada",
                otherUserID: "d7098386-8ca3-4db3-86f9-5f1008014846",
                viewed: false,
                type: MessageType.MESSAGE,
                information: { transactionId: '' },
            };

            
            //props.onNewMessage({ ...newMessage, updatedAt: moment().toISOString(), createdAt: moment().toISOString(), information: JSON.stringify(newMessage.information), viewed: false, user: { id: userId } })
            onSendMessage(newMessageRequestBy)
            .then(() => {
                //dispatch( setAddMessageReducer({ message: newMessageLocal, type }) )
            })
            .catch((r) => {
                console.error('error onSendMessage', r)
            }).finally(() => {
            })
            
        },
        [],
    )
*/

    return (
        <div className="flex con-header-table-history ff-roboto">

            {
                
                /*
            <div className="history-item1 flex flex-ai-center fs-14 mt-4 c-manatee">
                <button onClick={onSendPress}>asd</button>
            </div>
                */
            }

            <div className="history-item2 flex flex-ai-center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("History:label_04")}
                    orderColumn={orderColumn} 
                    columnName={"jobName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="history-item3 flex flex-ai-center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("History:label_05")}
                    orderColumn={orderColumn} 
                    columnName={"startTime"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="history-item4 flex flex-ai-center fs-13 mt-4 c-manatee">
                {type === "Job" ? "Gimmer" : "Gim"}
            </div>
            
            <div className="history-item5 flex flex-ai-center fs-14 mt-4 c-manatee">
                <p>{t("History:label_06")}</p>
            </div>
            
            <div className="history-item6 flex flex-ai-center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("History:label_07")}
                    orderColumn={orderColumn} 
                    columnName={"costTransaction"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="history-item7 flex flex-ai-center fs-13 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("History:label_08")}
                    orderColumn={orderColumn} 
                    columnName={"state"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="history-item8 flex flex-ai-center fs-13 mt-4 c-manatee">
                {t("History:label_09")}
            </div>

            <div className="history-item9 flex flex-ai-center fs-13 mt-4 c-manatee">

            </div>
        
        </div>
    )
}

export default React.memo( HeaderTableMyHistory )