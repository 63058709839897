import axios, { AxiosError, AxiosRequestConfig } from "axios"
import {
    adminApiUrl,
    getUserCredentials,
} from "../../axiosUtils"


export const getUrlPutGeneralInformation = (country: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.post(`${adminApiUrl}/v1/general-information`, { country }, config)
            .then((res) => { 
                resolve(res.data.url)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/general-information`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getGeneralInformation = () => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.get(`${adminApiUrl}/v1/general-information`, config)
            .then((res) => { 
                resolve(res.data.items)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/general-information`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
