import React, { useState } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms';
import { useAppDispatch } from '../../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { MyYup } from '../../../../../utils/verifications/myYup';

const FormDeleteContractModal = () => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)



    const handleSubmit = async(e: any) => {

        e.preventDefault()


    }

        
    return (
        <form onSubmit={handleSubmit} className="flex flex-d-column height-100 flex-jc-sb" >

        </form>
    )
}

export default React.memo( FormDeleteContractModal )