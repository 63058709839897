import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { 
    getUserCredentials,
    adminApiUrl,
} from "../../axiosUtils";
import { JobCategoryJob } from "../../../../redux/slices/components/ManagerJobs/managerJobDataSlice";


export const createNewJob = (categoryId: string, body: JobCategoryJob) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.post(`${adminApiUrl}/v1/job-category/${categoryId}/job`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/${categoryId}/job`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const deleteJob = (categoryId: string, jobId: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.delete(`${adminApiUrl}/v1/job-category/${categoryId}/job/${jobId}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/${categoryId}/job/${jobId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type activateJobBody = {
    newActivationState: boolean
}
export const activateJob = (categoryId: string, jobId: string, body: activateJobBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/job-category/${categoryId}/job/${jobId}/activate`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/${categoryId}/job/${jobId}/activate`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const editJob = (categoryId: string, jobId: string, body: JobCategoryJob) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.put(`${adminApiUrl}/v1/job-category/${categoryId}/job/${jobId}`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/${categoryId}/job/${jobId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
