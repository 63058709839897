import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../../../../../redux/store/store';
import { activateJobReducer, JobCategoryJob } from '../../../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import "./MenuJobRow.css"
import { resetValueFromState, setActivateJobState, setEditJobState } from '../../../../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import LoadingSpinner from '../../../../../../LoadingSpinner';
import { activateJob } from '../../../../../../../utils/requestsUtils/functionsRequests/jobCategories/JobFunctions';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface MenuJobRowProps {
    job: JobCategoryJob,
    categoryName: string,
    categoryId: string,
    topMenu: boolean,
    accessEditJob: boolean
    accessEditActivateJob: boolean
    accessLinkJob: boolean
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
}
  
const MenuJobRow = ({ 
    topMenu, 
    job, 
    categoryId, 
    categoryName, 
    accessEditJob,
    accessEditActivateJob,
    accessLinkJob,
    setShowMenu
 }: MenuJobRowProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false)

    const handleEditJob = () => {
        dispatch(setEditJobState({
            categoryId,
            job
        }))
        setShowMenu(false)
    }

    const handleActivateJob = () => {
        const dontAskAgain = localStorage.getItem("dontAskAgainActivation")
        if(dontAskAgain && JSON.parse(dontAskAgain)){
            setLoading(true)
            const newActivationState = !job.active
            activateJob(categoryId, job.id, { newActivationState })
            .then((res) => {
              dispatch(activateJobReducer({
                categoryId, jobId: job.id, newActivationState
              }))
              dispatch(resetValueFromState())
            })
            .finally(() => { setLoading(false)})
        }else{
            dispatch(setActivateJobState({ 
                newActivationState: !job.active,
                categoryId,
                categoryName,
                jobId: job.id,
                jobName: job.jobName 
            }))
        }
        setShowMenu(false)
    }

    const handleLinkToOther = () => {
        
    }

    useEffect(() => {
    
        const handleOutsideClick = (e: any) => {
            setTimeout(() => {
                setShowMenu(false)
            }, 50);
        }
        document.body.addEventListener('click', handleOutsideClick, true); // handle the capturing phase of an event
      
        return () => {
          document.body.removeEventListener('click', handleOutsideClick, true);
        }
      }, [setShowMenu])

      // elements with no-select class wont activate or deactivate the select status of the row when you click on them
    return (
        <div id="job-row-menu" className="con-menu fs-12" style={{ top: true ? "-7rem" : "2.4rem" }}>
            {
                accessEditJob &&
                <div onClick={handleEditJob} className="option-menu flex flex-ai-center pointer p-8 no-select">
                    <FontAwesomeIcon icon={solid("pen")} className="no-select" /> 
                    <p className="ml-10 mt-2 no-select">{t("General:label_068")}</p>
                </div> 
            } 
            {
                accessEditActivateJob &&
                <div onClick={handleActivateJob} className="option-menu flex flex-ai-center pointer p-8 no-select">
                    <FontAwesomeIcon icon={solid("plug")} className="no-select"/> 
                    <p className="ml-10 mt-2 no-select">{job.active ? t("General:label_084") : t("General:label_083")}</p>
                </div>  
            }
            <div onClick={handleLinkToOther} className="option-menu flex flex-ai-center pointer p-8 no-select">
                <FontAwesomeIcon icon={solid("link-simple")} className="no-select" /> 
                <p className="ml-10 mt-2 no-select">{t("General:label_085")}</p>
            </div>   
            { loading && <LoadingSpinner fullPage={true} /> }                        
        </div>
    )
}

export default MenuJobRow