import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, forwardRef, useImperativeHandle } from 'react'
import { MyYup } from '../../../utils/verifications/myYup';
import "./SearcherBar.css"

interface ISearcherBar {
    placeholder: string,
    searchSubmit: (searchString: string) => void,
    width?: string,
    addReset?: boolean,
    resetSearchBar?: () => void
}
  

const SearcherBar = forwardRef( ({ placeholder, searchSubmit, width = "28rem", addReset, resetSearchBar }: ISearcherBar, ref) => {

    const [searchBarFocused, setSearchBarFocused] = useState<boolean>(false);
    const [text, setText] = useState<string>("")
console.log("text", text)
    const focusInput = () => {
        setSearchBarFocused(true)
    }

    const blurInput = () => {
        setSearchBarFocused(false)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        
        if(!text)
            return

        searchSubmit(text)
    }

    useImperativeHandle(ref, () => ({
        submit() {
            setText("")
        }
    }));

    const reset = () => {
        setText("")
        if(resetSearchBar)
            resetSearchBar()
    }

    return (
        <div className={`container-input-searcher pointer ${searchBarFocused && "on-focus-container-input-searcher"}`} style={{ width }}>
            <form onSubmit={handleSubmit} className="container-icon-searcher">
                <div onClick={handleSubmit} className="con-searcher-len-icon">
                    <FontAwesomeIcon icon={solid("magnifying-glass")} className="search-len" />
                </div>
                <input 
                    type="text" 
                    placeholder={searchBarFocused ? "" : placeholder} 
                    className="input-searcher-bar pointer"
                    onFocus={focusInput}
                    onBlur={blurInput}
                    value={text}
                    onChange={(e: any) => {
                        const newText = e.target.value
                        const { errorMsg } = new MyYup(newText).justLettersAndUUID()
                        if(errorMsg && newText)
                            return
                        setText(newText)
                    }}
                />
                {
                    addReset && text &&
                    <div onClick={reset} className="center ml-6">
                        <FontAwesomeIcon className="fs-13 color-dark-hover" icon={solid("times")} />
                    </div>
                }
            </form>
            {
                //searchBarFocused &&
                false &&
                <div className="container-results-searcher">

                </div>
            }
        </div>
    )
})

export default SearcherBar