import { JobCategoryJob } from "../../redux/slices/components/ManagerJobs/managerJobDataSlice";
import { ValuesForm } from "../verifications/Forms";

type SetOptionalDataFromInputsJobCategory = {
    inputValues: ValuesForm

    nightCheckbox: boolean
    fixeCheckbox_Day: boolean
    timeTransactionCheckbox_Day: boolean
    transportCheckbox_Day: boolean
    tipCheckbox_Day: boolean
    productCheckbox_Day: boolean

    fixeCheckbox_Night: boolean
    timeTransactionCheckbox_Night: boolean
    transportCheckbox_Night: boolean
    tipCheckbox_Night: boolean
    productCheckbox_Night: boolean

    jobCategoryJob: JobCategoryJob
}

export const setOptionalDataFromInputsJobCategory = ({
    inputValues,

    nightCheckbox,
    fixeCheckbox_Day,
    timeTransactionCheckbox_Day,
    transportCheckbox_Day,
    tipCheckbox_Day,
    productCheckbox_Day,

    fixeCheckbox_Night,
    timeTransactionCheckbox_Night,
    transportCheckbox_Night,
    tipCheckbox_Night,
    productCheckbox_Night,

    jobCategoryJob
}: SetOptionalDataFromInputsJobCategory): JobCategoryJob => {

    if(fixeCheckbox_Day){
        jobCategoryJob["pricingInformation"]["day"]["fixe"] = {
            price:  Number(inputValues["fixedPrice_Day"].value),
            gimIVA: Number(inputValues["fixedGimIVA_Day"].value),
            gimmerIVA: Number(inputValues["fixedGimmerIVA_Day"].value),
            gimworkIVA: Number(inputValues["fixedGimworkIVA_Day"].value),
            ISR: Number(inputValues["fixedISR_Day"].value),
            RMP: Number(inputValues["fixedRMP_Day"].value)
        }
    }


    if(timeTransactionCheckbox_Day){
        jobCategoryJob["pricingInformation"]["day"]["timeTransaction"] = {
            gimIVA: Number(inputValues["timeTransactionGimIVA_Day"].value),
            gimmerIVA: Number(inputValues["timeTransactionGimmerIVA_Day"].value),
            gimworkIVA: Number(inputValues["timeTransactionGimworkIVA_Day"].value),
            ISR: Number(inputValues["timeTransactionISR_Day"].value),
            RMP: Number(inputValues["timeTransactionRMP_Day"].value)
        }
    }


    if(transportCheckbox_Day){
        jobCategoryJob["pricingInformation"]["day"]["transportCosts"] = {
            price:  Number(inputValues["transportCostsPrice_Day"].value),
            gimIVA: Number(inputValues["transportCostsGimIVA_Day"].value),
            gimmerIVA: Number(inputValues["transportCostsGimmerIVA_Day"].value),
            gimworkIVA: Number(inputValues["transportCostsGimworkIVA_Day"].value),
            ISR: Number(inputValues["transportCostsISR_Day"].value),
            RMP: Number(inputValues["transportCostsRMP_Day"].value)
        }
    }


    if(tipCheckbox_Day){
        jobCategoryJob["pricingInformation"]["day"]["tips"] = {
            tip:  Number(inputValues["tipMin_Day"].value),
            gimIVA: Number(inputValues["tipGimIVA_Day"].value),
            gimmerIVA: Number(inputValues["tipGimmerIVA_Day"].value),
            gimworkIVA: Number(inputValues["tipGimworkIVA_Day"].value),
            ISR: Number(inputValues["tipISR_Day"].value),
            RMP: Number(inputValues["tipRMP_Day"].value),
        }
    }

    if(productCheckbox_Day){
        jobCategoryJob["pricingInformation"]["day"]["product"] = {
            gimmerIVA: Number(inputValues["productGimmerIVA_Day"].value),
            gimworkIVA: Number(inputValues["productGimworkIVA_Day"].value),
            RMPC: Number(inputValues["productRMPC_Day"].value),
        }
    }

    console.log("qwe", inputValues["nightStartTime"].value)
    if(nightCheckbox){

        const startTime = inputValues["nightStartTime"].value === "24:00" ? "00:00" : inputValues["nightStartTime"].value 
        const endTime = inputValues["nightEndTime"].value === "24:00" ? "00:00" : inputValues["nightEndTime"].value

        jobCategoryJob["pricingInformation"]["night"] = {
            price: Number(inputValues["price_Night"].value),
            malusGim: Number(inputValues["malusGim_Night"].value),
            malusGimmer: Number(inputValues["malusGimmer_Night"].value),
            malusShareGim: Number(inputValues["malusShareGim_Night"].value),
            malusShareGimmer: Number(inputValues["malusShareGimmer_Night"].value),
            IVA: Number(inputValues["IVA_Night"].value),

            nightHours: {
                startTime: inputValues["nightStartTime"].value,
                endTime: inputValues["nightEndTime"].value,
            },
            timeTransaction: {
                gimIVA: Number(inputValues["timeTransactionGimIVA_Night"].value),
                gimmerIVA: Number(inputValues["timeTransactionGimmerIVA_Night"].value),
                gimworkIVA: Number(inputValues["timeTransactionGimworkIVA_Night"].value),
                ISR: Number(inputValues["timeTransactionISR_Night"].value),
                RMP: Number(inputValues["timeTransactionRMP_Night"].value)
            }
        }

        if(fixeCheckbox_Night){
            jobCategoryJob["pricingInformation"]["night"]["fixe"] = {
                price:  Number(inputValues["fixedPrice_Night"].value),
                gimIVA: Number(inputValues["fixedGimIVA_Night"].value),
                gimmerIVA: Number(inputValues["fixedGimmerIVA_Night"].value),
                gimworkIVA: Number(inputValues["fixedGimworkIVA_Night"].value),
                ISR: Number(inputValues["fixedISR_Night"].value),
                RMP: Number(inputValues["fixedRMP_Night"].value)
            }
        }

        if(timeTransactionCheckbox_Night){
            jobCategoryJob["pricingInformation"]["night"]["timeTransaction"] = {
                gimIVA: Number(inputValues["timeTransactionGimIVA_Night"].value),
                gimmerIVA: Number(inputValues["timeTransactionGimmerIVA_Night"].value),
                gimworkIVA: Number(inputValues["timeTransactionGimworkIVA_Night"].value),
                ISR: Number(inputValues["timeTransactionISR_Night"].value),
                RMP: Number(inputValues["timeTransactionRMP_Night"].value)
            }
        }


        if(transportCheckbox_Night){
            jobCategoryJob["pricingInformation"]["night"]["transportCosts"] = {
                price:  Number(inputValues["transportCostsPrice_Night"].value),
                gimIVA: Number(inputValues["transportCostsGimIVA_Night"].value),
                gimmerIVA: Number(inputValues["transportCostsGimmerIVA_Night"].value),
                gimworkIVA: Number(inputValues["transportCostsGimworkIVA_Night"].value),
                ISR: Number(inputValues["transportCostsISR_Night"].value),
                RMP: Number(inputValues["transportCostsRMP_Night"].value)
            }
        }

        if(tipCheckbox_Night){
            jobCategoryJob["pricingInformation"]["night"]["tips"] = {
                tip:  Number(inputValues["tipMin_Night"].value),
                gimIVA: Number(inputValues["tipGimIVA_Night"].value),
                gimmerIVA: Number(inputValues["tipGimmerIVA_Night"].value),
                gimworkIVA: Number(inputValues["tipGimworkIVA_Night"].value),
                ISR: Number(inputValues["tipISR_Night"].value),
                RMP: Number(inputValues["tipRMP_Night"].value),
            }
        }

        if(productCheckbox_Night){
            jobCategoryJob["pricingInformation"]["night"]["product"] = {
                gimmerIVA: Number(inputValues["productGimmerIVA_Night"].value),
                gimworkIVA: Number(inputValues["productGimworkIVA_Night"].value),
                RMPC: Number(inputValues["productRMPC_Night"].value),
            }
        }
    }

    return jobCategoryJob
}


type GetCheckDependentJobValues = {
    nightCheckbox: boolean
    fixeCheckbox_Day: boolean
    timeTransactionCheckbox_Day: boolean
    transportCheckbox_Day: boolean
    tipCheckbox_Day: boolean
    productCheckbox_Day: boolean

    fixeCheckbox_Night: boolean
    timeTransactionCheckbox_Night: boolean
    transportCheckbox_Night: boolean
    tipCheckbox_Night: boolean
    productCheckbox_Night: boolean
}

export const getCheckDependentJobValues = ({
    nightCheckbox,
    fixeCheckbox_Day,
    timeTransactionCheckbox_Day,
    transportCheckbox_Day,
    tipCheckbox_Day,
    productCheckbox_Day,

    fixeCheckbox_Night,
    timeTransactionCheckbox_Night,
    transportCheckbox_Night,
    tipCheckbox_Night,
    productCheckbox_Night,
}: GetCheckDependentJobValues, group?: string): boolean => {

    // true: input depends of a checkbox and the state of this is true, then it will be checked
    // false: input depends of a checkbox and the state of this is false, then it wont be checked

    if(!group)
        return true

    const words = group?.split("_")

    const groupName = words[0] as "principal" | "fixe" | "timeTransaction" | "transportCosts" | "tip" | "product" | "hour"
    const groupdPeriod: "night" | "day" = words[1] as "night" | "day"

    if(groupName === "principal") return nightCheckbox ? true : false
    if(groupName == "hour")

    if(groupName == "hour") return nightCheckbox;

    if(groupName === "fixe") return groupdPeriod === "day" ? fixeCheckbox_Day : ( nightCheckbox ? fixeCheckbox_Night : false)
    else if(groupName === "timeTransaction") return groupdPeriod === "day" ? true : ( nightCheckbox ? true : false)
    else if(groupName === "transportCosts") return groupdPeriod === "day" ? transportCheckbox_Day : ( nightCheckbox ? transportCheckbox_Night : false)
    else if(groupName === "tip") return groupdPeriod === "day" ? tipCheckbox_Day : ( nightCheckbox ? tipCheckbox_Night : false)
    else if(groupName === "product") return groupdPeriod === "day" ? productCheckbox_Day : ( nightCheckbox ? productCheckbox_Night : false)
    
    return true
}