import React from 'react'
import { S3DomainGimwork_country_flags_w80 } from '../../../../utils/staticVariables/staticVariables'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./RowGeneralInformation.css"
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../redux/store/store'
import useCheckAccess from '../../../hooks/useCheckAccess/useCheckAccess'
import { setEditGeneralInformationModalState } from '../../../../redux/slices/components/GeneralInformation/GeneralInformationSlice'

type RowGeneralInformationProps = {
    countryName: string
    gimworkFee: string
    countryTax: string
    index: number
}

const RowGeneralInformation = ({ countryName, gimworkFee, countryTax, index }: RowGeneralInformationProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const accessCreate = useCheckAccess({ id_Category: "General_Information", id_Policy: "Shop_Fees", id_Action: "Edit" })

    const editContract = () => {
        dispatch( setEditGeneralInformationModalState({
            countryName,
            gimworkFee,
            countryTax
        }) )
    }

    return (
        <div className="flex flex-d-column" style={{ marginTop: `${index * 2.5 + 3}rem` }}>
            <div className="flex flex-ai-center">
                <div 
                    className="img-flag-dropdown"
                    style={{ backgroundImage: `url("${S3DomainGimwork_country_flags_w80}${countryName}.png")` }}>
                </div>
                <p className="fw-600 fs-20 ml-16 ff-roboto">{countryName}</p>
            </div>

            <div className="flex flex-ai-center width-100 dark-gray-bg height-gi-item mt-14" >
                <div className="flex item1-gi">
                    <FontAwesomeIcon icon={regular("scroll-old")} className={"c-white fs-18 ml-20"} /> 
                    <p className="fs-16 c-white ml-10">{"Shop Fees"}</p>
                </div>
                <div className="item2-gi">
                    <p className="fs-18 c-white text-align-right">%</p>
                </div>
                <div className="flex flex-1"></div>
                {
                    accessCreate &&
                    <button onClick={editContract} className="pointer">
                        <FontAwesomeIcon icon={solid("pen")} className={"c-white fs-18 mr-20"} /> 
                    </button>
                }
            </div>


            <div className="flex flex-ai-center athens-gray-bg height-gi-item">
                <div className="item1-gi">
                    <p className="fs-16 ml-text-gi">{"GIMWORK"}</p>
                </div>
                <div className="item2-gi text-align-right">
                    <p className="fs-16 text-align-right">{`${gimworkFee}%`}</p>
                </div>
            </div>


            <div className="flex flex-ai-center athens-gray-bg height-gi-item">
                <div className="item1-gi">
                    <p className="fs-16 ml-text-gi">{"Country Tax"}</p>
                </div>
                <div className="item2-gi">
                    <p className="fs-16 text-align-right">{`${countryTax}%`}</p>
                </div>
            </div>

        </div>
    )
}

export default React.memo( RowGeneralInformation )