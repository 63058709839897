import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import { GetUsersReportsBySearchThunk, GetUsersReportsThunk, numberUserReportsByPage } from '../../../redux/slices/components/ManageReports/manageReportsDataSlice';
import { getPaginatedData } from '../../../utils/strings/strings';
import { useTranslation } from 'react-i18next';
import React from 'react';

const PaginationReportTableRow = () => {
    
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    
    const userReportsIds = useSelector((state: RootState) => state.manageUserReports.userReportsIds);
    const LastEvaluatedKey = useSelector((state: RootState) => state.manageUserReports.LastEvaluatedKey);
    const total = useSelector((state: RootState) => state.manageUserReports.total);
    const stringToSearch = useSelector((state: RootState) => state.manageUserReports.stringToSearch);


    const userReportsToShow = useSelector((state: RootState) => state.manageUserReports.userReportsToShow);
    const loadingContainer = useSelector((state: RootState) => state.manageUserReports.loadingContainer);
    const limit = useSelector((state: RootState) => state.manageUserReports.limit);
    const page = useSelector((state: RootState) => state.manageUserReports.page);
  
    const openIncident = useSelector((state: RootState) => state.manageUserReports.openIncident);
    const gimGimmerAll = useSelector((state: RootState) => state.manageUserReports.gimGimmerAll);
    const userId = useSelector((state: RootState) => state.manageUserReports.userId);
    const supportSent = useSelector((state: RootState) => state.manageUserReports.supportSent);
    const justSupportLevel = useSelector((state: RootState) => state.manageUserReports.justSupportLevel);

    const offersLength = userReportsToShow.length;
    


    const paginate = (direction: string) => {
        
        if(loadingContainer)
            return

        if(direction === "forward" && LastEvaluatedKey){
            if(stringToSearch)
                dispatch(GetUsersReportsBySearchThunk({ 
                    page: page + 1, 
                    limit: 100, 
                    bringTotal: true,  
                    stringToSearch,
                }))
            else
                dispatch(GetUsersReportsThunk({ 
                    page: page + 1, 
                    limit, 
                    LastEvaluatedKey, 
                    bringTotal: true,
                    gimGimmerAll,
                    openIncident,
                    userId,
                    supportSent,
                    justSupportLevel 
                }))
            }
        else if(direction === "backward" && page !== 1){
            if(page === 2){
                dispatch(GetUsersReportsThunk({ 
                    page: page - 1, 
                    limit, 
                    LastEvaluatedKey: undefined, 
                    bringTotal: true,
                    gimGimmerAll,
                    openIncident,
                    userId,
                    supportSent,
                    justSupportLevel 
                }))
            }else{
                const lastKeyId = userReportsIds[((page - 2) * limit) - 1]
                dispatch(GetUsersReportsThunk({ 
                    page: page - 1, 
                    limit, 
                    LastEvaluatedKey: lastKeyId, 
                    bringTotal: true,
                    gimGimmerAll,
                    openIncident,
                    userId,
                    supportSent,
                    justSupportLevel 
                }))
            }
        }

    }

    const changeNumberOfPages = (newNumberOfPages: number) => {

        const totalUserReportssUntilNow = (page - 1) * limit + userReportsToShow.length 

        if(newNumberOfPages > totalUserReportssUntilNow){
            // bring page 1 with same type
            dispatch(GetUsersReportsThunk({ 
                page: 1, 
                limit: newNumberOfPages, 
                bringTotal: true,
                gimGimmerAll,
                openIncident,
                userId,
                supportSent,
                justSupportLevel 
            }))
        }else{
            dispatch(GetUsersReportsThunk({ 
                page: 1, 
                limit: newNumberOfPages, 
                bringTotal: true,
                gimGimmerAll,
                openIncident,
                userId,
                supportSent,
                justSupportLevel 
            }))
            /*
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            const newLastEvaluatedKey = offersToShow[newNumberOfPages - 1].id
            dispatch(GetOffersThunk({ type, page: newPage, limit: newNumberOfPages, LastEvaluatedKey: newLastEvaluatedKey, bringTotal: false }))
            */
        }
    }

    const changeNumberOfPagesSearchBar = (newNumberOfPages: number) => {
        
        if(!stringToSearch)
            return

        const totalUserReportssUntilNow = (page - 1) * limit + userReportsToShow.length 

        if(newNumberOfPages > totalUserReportssUntilNow ){
            // bring page 1 with same type
            dispatch(GetUsersReportsBySearchThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, stringToSearch }))
        }else{
            
            dispatch(GetUsersReportsBySearchThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, stringToSearch}))
            /*
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            const newLastEvaluatedKey = offersToShow[newNumberOfPages - 1].id
            dispatch(GetOffersThunk({ type, page: newPage, limit: newNumberOfPages, LastEvaluatedKey: newLastEvaluatedKey, bringTotal: false }))
            */
        }
    }

    return (
        <div className="con-row-pagination flex flex-jc-sb flex-ai-center width-100">
            
            <div className=""></div>
            
            <div className="flex flex-ai-center">
                <button 
                    onClick={() => paginate("backward")} 
                    className={`fs-24 mr-12 ${page === 1 ? "disable-color" : "c-black pointer"}`}
                    disabled={page === 1 ? true : false} 
                >
                    <FontAwesomeIcon icon={solid("arrow-left-long")}  />
                </button>
                <p className="fs-15 fw-700">{page}</p>
                <button 
                    onClick={() => paginate("forward")}  
                    className={`fs-24 ml-12 ${LastEvaluatedKey ?  "c-black pointer" : "disable-color"}`}
                    disabled={!LastEvaluatedKey ? true : false}
                >
                    <FontAwesomeIcon icon={solid("arrow-right-long")}  />
                </button>
            </div>
            
            <div className="flex">
                {
                    numberUserReportsByPage.map((numberPages: number, index: number) => {
                        return (
                            <button 
                                key={index}
                                disabled={numberPages === limit}
                                className={`fs-14 fw-500 mr-12 ${numberPages === limit ? "c-black" : "disable-color pointer"}`}
                                onClick={() => { 
                                    if(stringToSearch)
                                        changeNumberOfPagesSearchBar(numberPages)
                                    else
                                        changeNumberOfPages(numberPages)
                                }}
                            >
                                {numberPages}
                            </button>
                        )
                    })
                }
                <p className="fw-500 fs-14 ml-10">
                    {`${getPaginatedData(page, limit, total)} ${t("General:label_267")} ${total}`}
                </p>
            </div>

        </div>
    )
}

export default React.memo( PaginationReportTableRow )