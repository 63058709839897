import React, { useCallback } from 'react'
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import { useSelector } from 'react-redux';
import FormEditGeneralInformationModal from './FormEditContractModal/FormEditGeneralInformationModal';
import { resetModalsValueFromGeneralInformationState } from '../../../../redux/slices/components/GeneralInformation/GeneralInformationSlice';

const EditGeneralInformationModal = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();

    const information = useSelector((state: RootState) => state.generalInformation.EditGeneralInformationModal);
  
    const closeModal = useCallback(
      () => {
          dispatch( resetModalsValueFromGeneralInformationState() )
      }
      ,
      [],
  )
  
    return (
      <div className="blank-wrapper-modals flex flex-d-column p-relative" >
          <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
              <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
          </button>
          <div className="center pr-10">
              <TitleModal title={"Edit Fees"} />
          </div>
          <FormEditGeneralInformationModal closeModal={closeModal} information={information} />
      </div>
    )
}

export default React.memo( EditGeneralInformationModal )