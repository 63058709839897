
import React, { useState, useEffect } from "react"
import { setDetailedOfferReducer } from "../../../../redux/slices/components/ManageOffers/manageDetailedOfferSlice"
import { Offer, updateSeenOfferReducer } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { useAppDispatch } from '../../../../redux/store/store'
import { updateSeenOffer } from "../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions"
import { getProfilePictureByUserId } from "../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import { GetOfferType, UserInfoCache, UserInfoCacheType, getMinimalUserInfoData } from "../../../../utils/staticVariables/staticVariables"
import "./BugReportRow.css"
import { BugReportTableItem, updateSeenBugReportReducer } from "../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice"
import { updateBugReportSeenWeb } from "../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions"
import { FaCircle } from "react-icons/fa"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import StatusReport from "../../../UIcomponents/Texts/StatusReport/StatusReport"
import StatusReportText from "../../../UIcomponents/Texts/StatusReport/StatusReport"
import PriorityReportLabel from "../../../UIcomponents/Texts/PriorityReport/PriorityReport"
import useGetTimeLeft from "../../../hooks/dates/useGetTimeLeft"
import { useNavigate } from "react-router-dom"

export interface BugReportRowProps {
    bugReport: BugReportTableItem
}

const BugReportRow = React.memo(({ bugReport }: BugReportRowProps) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const prettyDate = useGetTimeLeft(bugReport.timestamp)

    const [userNameRequestBy, setUserNameRequestBy] = useState<string>("")
    const [userImgRequestBy, setUserImgRequestBy] = useState<string>("")
    const [userSupportAssigned, setUserSupportAssigned] = useState<string>("")


    useEffect(() => {

        const getData = () => {
            const user = UserInfoCache.userNameCache.get(bugReport.requestBy)
            if(user?.name){
                setUserNameRequestBy(user.name)
            }else{
                getMinimalUserInfoData(bugReport.requestBy)
                .then((userInfo: UserInfoCacheType) => {
                    setUserNameRequestBy(userInfo.name)
                })
            }

            if(bugReport.userAssigned){
                const userPersonInvolved = UserInfoCache.userNameCache.get(bugReport.userAssigned)
                if(userPersonInvolved?.name){
                    setUserSupportAssigned(userPersonInvolved.name)
                }else{
                    getMinimalUserInfoData(bugReport.userAssigned)
                    .then((userInfo: UserInfoCacheType) => {
                        setUserSupportAssigned(userInfo.name)
                    }).catch((error: any) => {console.log("error", error)})
                }
            }
    
    
            getProfilePictureByUserId(bugReport.requestBy, "small")
            .then((imgUrl) => {
                setUserImgRequestBy(imgUrl)
            })
        }

        getData()
    }, [])
    
    

    const clickOnOffer = () => {
        //dispatch( setDetailedBugReportReducer({ bugReport }) )
        navigate(`/manage-bug-reports/${bugReport.id}`)
        updateBugReportSeenWeb( bugReport.id )
        .then(() => {
            dispatch( updateSeenBugReportReducer({ id: bugReport.id}) )
        }).catch((error) => {
            console.log("error", error)
        })
        //dispatch(setDetailedOfferReducer({bugReport, imgUrl: userImg}))
        
    }

    return (
        <div onClick={clickOnOffer} className="container-row-report-bug flex mt-8 mb-8 pointer pl-10">
            
            {
                // Seen circle
                !bugReport.seen &&
                <div className="con-circle-saw center">
                    <div className="circle-saw circle"></div>
                </div>
            }

            {
                // report Id
            }
            <div className="report-bug-item1 height-100 center">
                <p title={bugReport.id} className="id-report-bug fs-14 fw-500">{bugReport.id}</p>
            </div>

            {
                // request by
            }
            <div className="report-bug-item2 height-100 flex flex-ai-center">
                <div className="img-circular-row circle" style={{ backgroundImage: `url("${userImgRequestBy}")` }}></div>
                <p className="ml-10 fs-14 fw-500 three-dot">{userNameRequestBy}</p>
            </div>

            {
                // subject
            }
            <div className="report-bug-item3 height-100 flex flex-ai-center">
                <p className="fs-14 fw-500 three-dot">{bugReport.subject}</p>
            </div>

            {
                // status
            }
            <div className="report-bug-item4 height-100 flex flex-ai-center">
                <StatusReportText type={bugReport.status} />
            </div>

            {
                // status
            }
            <div className="report-bug-item5 height-100 flex flex-ai-center">
                <PriorityReportLabel type={bugReport.priority} />
            </div>

            {
                // Creation Date
            }
            <div className="report-bug-item6 height-100 flex flex-ai-center">
                <p className="fs-12 fw-600">{prettyDate ?? bugReport.creationDate}</p>
            </div>

            {
                // Assignee
            }
            <div className="report-bug-item7 height-100 flex flex-ai-center">
                <p className={`fs-14 fw-600 color-text`}>{userSupportAssigned}</p>
            </div>


        </div>
    )
})

export default BugReportRow