import SideBar from '../../../SideBar/SideBar/SideBar'
import "./GeneralWrapperScreen.css"

interface GeneralWrapperScreen {
    width?: string,
    RouteComponent: () => JSX.Element
}

const GeneralWrapperScreen = ({ RouteComponent, width = "100%" }:GeneralWrapperScreen) => {
  return (
    <div className="container-screens">
        <div className="container-content">
            <div className="container-sidebar">
                <SideBar />
            </div>
            <div className="container-screen">
                <div style={{ width }} className="container-aux-design-screen"  >
                    <RouteComponent />
                </div>
            </div>
        </div>
    </div>
  )
}

export default GeneralWrapperScreen