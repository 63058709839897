import React from 'react'
import { useAppDispatch } from '../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import FormDeleteGeneralInformationModal from './FormDeleteContractModal/FormDeleteGeneralInformationModal';

const DeleteGeneralInformationModal = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();
  
    const closeModal = () => {
      //dispatch(resetValueFromState())
    }
  
    return (
      <div className="blank-wrapper-modals flex flex-d-column height-job-modal-with-scroll p-relative" >
          <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
              <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
          </button>
          <div className="flex flex-jc-sb flex-ai-center pr-10">
              <TitleModal title={t("General:label_123")} />
          </div>
          <FormDeleteGeneralInformationModal />
      </div>
    )
}

export default DeleteGeneralInformationModal