import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import { GetBugReportsBySearchThunk, GetBugReportsThunk, LastEvaluatedKeyBugReports, numberBugReportsByPage } from '../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice';
import { getPaginatedData } from '../../../utils/strings/strings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const PaginationBugReportTableRow = () => {
    
    const { t } = useTranslation()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    
    const bugReportsToShow = useSelector((state: RootState) => state.manageBugReportsData.bugReportsToShow);
    const loadingContainer = useSelector((state: RootState) => state.manageBugReportsData.loadingContainer);
    const bugReportsIds = useSelector((state: RootState) => state.manageBugReportsData.bugReportsIds);
    const LastEvaluatedKey =  useSelector((state: RootState) => state.manageBugReportsData.LastEvaluatedKey);
    const limit = useSelector((state: RootState) => state.manageBugReportsData.limit);
    const page = useSelector((state: RootState) => state.manageBugReportsData.page);
    const total = useSelector((state: RootState) => state.manageBugReportsData.total);
    const stringToSearch = useSelector((state: RootState) => state.manageBugReportsData.stringToSearch);


    const status = useSelector((state: RootState) => state.manageBugReportsData.status);
    const offersLength = bugReportsToShow.length;

    const paginate = (direction: string) => {
        //console.log("lastEvaluatedKey", LastEvaluatedKey)
        if(loadingContainer)
            return

        /*
                if(status === newStatus)
            navigate(`/manage-bug-reports?limit=${limit}`)
        else
            navigate(`/manage-bug-reports?limit=${limit}&status=${newStatus}`)
        */

        if(direction === "forward" && LastEvaluatedKey){
            dispatch(GetBugReportsThunk({ page: page + 1, limit, LastEvaluatedKey, bringTotal: true, status }))
        }
        else if(direction === "backward" && page !== 1){
            if(page === 2){
                dispatch(GetBugReportsThunk({ page: page - 1, limit, LastEvaluatedKey: undefined, bringTotal: true, status }))
            }else{
                const lastItem = bugReportsIds[((page - 2) * limit) - 1]
                const lastKeyId: LastEvaluatedKeyBugReports = { id: lastItem.id }
                if(status){
                    lastKeyId["status"] = status
                    lastKeyId["timestamp"] = lastItem.timestamp
                }
                    
                dispatch(GetBugReportsThunk({ page: page - 1, limit, LastEvaluatedKey: lastKeyId, bringTotal: true, status }))
            }
        }

    }

    const changeNumberOfPages = (newNumberOfPages: number) => {

        if(!status)
            navigate(`/manage-bug-reports?limit=${newNumberOfPages}`)
        else
            navigate(`/manage-bug-reports?status=${status}&limit=${newNumberOfPages}`)
/*

        //const totalBugReportssUntilNow = (page - 1) * limit + bugReportsToShow.length  

        if(newNumberOfPages > totalBugReportssUntilNow){
            // bring page 1 with same type
            dispatch(GetBugReportsThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, status}))
        }else{
            dispatch(GetBugReportsThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, status}))
            
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            const newLastEvaluatedKey = offersToShow[newNumberOfPages - 1].id
            dispatch(GetOffersThunk({ type, page: newPage, limit: newNumberOfPages, LastEvaluatedKey: newLastEvaluatedKey, bringTotal: false }))
            
        }
            */
    }

    const changeNumberOfPagesSearchBar = (newNumberOfPages: number) => {
        
        if(!stringToSearch)
            return

        const totalBugReportssUntilNow = (page - 1) * limit + bugReportsToShow.length 

        if(newNumberOfPages > totalBugReportssUntilNow ){
            // bring page 1 with same type
            dispatch(GetBugReportsBySearchThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, stringToSearch }))
        }else{
            
            dispatch(GetBugReportsBySearchThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, stringToSearch}))
            /*
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            const newLastEvaluatedKey = offersToShow[newNumberOfPages - 1].id
            dispatch(GetOffersThunk({ type, page: newPage, limit: newNumberOfPages, LastEvaluatedKey: newLastEvaluatedKey, bringTotal: false }))
            */
        }
    }

    return (
        <div className="con-row-pagination flex flex-jc-sb flex-ai-center width-100">
            
            <div className=""></div>
            
            <div className="flex flex-ai-center">
                <button 
                    onClick={() => paginate("backward")} 
                    className={`fs-24 mr-12 ${page === 1 ? "disable-color" : "c-black pointer"}`}
                    disabled={page === 1 ? true : false} 
                >
                    <FontAwesomeIcon icon={solid("arrow-left-long")}  />
                </button>
                <p className="fs-15 fw-700">{page}</p>
                <button 
                    onClick={() => paginate("forward")}  
                    className={`fs-24 ml-12 ${LastEvaluatedKey?.id ?  "c-black pointer" : "disable-color"}`}
                    disabled={!LastEvaluatedKey?.id ? true : false}
                >
                    <FontAwesomeIcon icon={solid("arrow-right-long")}  />
                </button>
            </div>
            
            <div className="flex">
                {
                    numberBugReportsByPage.map((numberPages: number, index: number) => {
                        return (
                            <button 
                                key={index}
                                disabled={numberPages === limit}
                                className={`fs-14 fw-500 mr-12 ${numberPages === limit ? "c-black" : "disable-color pointer"}`}
                                onClick={() => { 
                                    if(stringToSearch)
                                        changeNumberOfPagesSearchBar(numberPages)
                                    else
                                        changeNumberOfPages(numberPages)
                                }}
                            >
                                {numberPages}
                            </button>
                        )
                    })
                }
                <p className="fw-500 fs-14 ml-10">
                    {`${getPaginatedData(page, limit, total)} ${t("General:label_267")} ${total}`}
                </p>
            </div>

        </div>
    )
}

export default PaginationBugReportTableRow