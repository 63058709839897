import React, { useCallback, useEffect } from 'react'
import ArrowGoBackSignUp from '../../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../../components/UIcomponents/NextButton/NextButton'
import { useNavigate } from 'react-router-dom'
import { getAllJobsCategories } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions'
import { Colors } from '../../../../utils/colors/colors'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CategoryJob } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import { S3DomainGimwork_categories_logo } from '../../../../utils/staticVariables/staticVariables'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { selectCategoryJobs, setCategoryJobs } from '../../../../redux/slices/components/SignUp/SignUpSlice'
import { useSelector } from 'react-redux'
import ModalSelectJob from '../../../../components/SignUp/SkillGim/SelectSkill/ModalSelectJob/ModalSelectJob'
import { Coords, requestUserPosition } from '../../../../utils/maps/locations'

const SelectSkillScreen = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const categories = useSelector((state: RootState) => state.signUp.categoryJobs)

    //const userJobs = useSelector((state: RootState) => state.auth.user.jobs)
    //const jobsAdded = useSelector((state: RootState) => state.signUp.jobsAdded)
    //console.log(" ")
    //console.log("userJobs select", userJobs)
    //console.log("jobsAdded select", jobsAdded)

    const onNext = useCallback(
        () => {
            navigate("/welcome")
        },
        [],
    )

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )
    
    useEffect(() => {
      
        getAllJobsCategories()
        .then((categories) => {
            dispatch( setCategoryJobs( categories ) )
        }).catch(() => {

        })
        
    }, [])

    useEffect(() => {

        const getData = () => {

            requestUserPosition()
            .then((coords: Coords) => {
                
            }).catch((error) => {
                console.log("Error: ", error)
            })
        }

        getData()
      
    }, [])

    const selectCategory = (id: string) => {
        dispatch( selectCategoryJobs(id) )
    }

    window.onpopstate = (event) => {
        console.log(
            `location: ${document.location}, state: ${JSON.stringify(event.state)}`,
          );
    }

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-d-column" style={{ marginTop: "11.5vh" }}>

                <p className="fs-36-vh c-text">{"Please add your skills"}</p>

                <p className="fs-18-vh c-text" style={{ marginTop: "1.4vh" }}>{"They will be added to your profile"}</p>

                <div className="flex flex-jc-sb" style={{ marginTop: "4.6vh" }}>
                    <p className="fs-20-vh c-text">{"Categories"}</p>

                    <div    
                        style={{ 
                            borderBottomWidth: "1px",
                            borderBottomStyle: "solid", 
                            borderBottomColor: Colors.TEXT,
                            width: "36%"
                        }} 
                        className="flex flex-ai-center"
                    >
                        <FontAwesomeIcon icon={solid("magnifying-glass")} className="color-text fs-18 mr-10" />
                        <input 
                            className="outline-none fs-18-vh"
                            placeholder={"Search for a skill"}
                        />
                    </div>
                </div>

                <div className="flex" style={{ flexWrap: "wrap", marginTop: "2.8vh" }}>
                    {
                        categories.map((category: CategoryJob, index: number) => {
                            return(
                                <div 
                                    key={category.id}
                                    onClick={() => selectCategory(category.id)}
                                    className="pointer shadow-box-select-skill flex flex-d-column mr-6 ml-10"
                                    style={{
                                        width: "16.4%",
                                        height: "12.2vh",
                                        backgroundColor: "#3A41531F"
                                    }}
                                >
                                    <img 
                                        src={`${S3DomainGimwork_categories_logo}${category.id}/categoryImg_200`}
                                        style={{
                                            height: "81%",
                                            width: "100%", 
                                            objectFit: "cover"
                                        }}
                                    />
                                    <div className="center flex-1">
                                        <p className="fs-14-vh text-3-points color-text mt-1">{category.categoryName}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="center" style={{ marginTop: "2.5vh" }}>
                    <button
                        style={{
                            border: `1px solid ${Colors.Gim}`,
                            width: "35%",
                            height: "4.4vh"
                        }}
                        className="border-radius-3 pointer"
                        onClick={() => selectCategory("All")}
                    >
                        <p className="fs-20-vh color-gim">{"See all sectors and skills"}</p>
                    </button>
                </div>

            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={"Skip"} 
                />
            </div>

            <ModalSelectJob />

        </div>
    )
}

export default SelectSkillScreen