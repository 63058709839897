import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { TypeOffers } from "../../../../redux/slices/components/ManageOffers/manageOffersDataSlice"
import {
    DeleteMultipleUsers,
    GetUser,
    adminApiUrl,
    getUserCredentials
} from "../../axiosUtils"
import { Currency, Job, LanguageLabel } from "../../../../redux/slices/authSlice"
import { GimGimmer, gimgimmer } from "../offers/offersFunctions"
import { Body_INE } from "../../../INE/INE"



export const getUser = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        GetUser(id)
        .then((res) => {resolve(res.data)})
        .catch((error) => { console.log("error -------------------", error); reject(error) })
    })
} 

export const getMyUser = () => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.get(`${adminApiUrl}/v1/user/${session.userId}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                //console.log("Error: ", `${adminApiUrl}/v1/user/${session.userId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



type DeleteMultipleUsersBody = {
    usersIds: string[]
}

export const deleteMultipleUsers = (body: DeleteMultipleUsersBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        DeleteMultipleUsers(body)
        .then((res) => {resolve(res.data)})
        .catch((error) => { console.log("error", error); reject(error) })
    })
} 



type GetUsersBySearchBody = {
    LastEvaluatedKey?: string,
    limit: number,
    bringTotal: boolean,
    stringToSearch: string
}

export const getUsersBySearch = (params : GetUsersBySearchBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params
            };

            axios.get(`${adminApiUrl}/v1/user/search-manage-users`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/search-manage-users`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const deleteUser = (userId: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, }
            };

            axios.delete(`${adminApiUrl}/v1/user/${userId}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${userId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const deleteMyUser = () => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, }
            };

            axios.delete(`${adminApiUrl}/v1/user/${session.userId}/my-user`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${session.userId}/my-user`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



type GetUsersBody = {
    LastEvaluatedKey?: string,
    type: string,
    limit: number,
    bringTotal: boolean
}

export const getUsers = (params: GetUsersBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params
            };

            axios.get(`${adminApiUrl}/v1/user`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getProfilePictureByUserId = (userId: string, size: "small" | "mid" | "" = "") => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const type = `/profilPic${size ? `_${size}` : ""}.jpeg`
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { id: userId, type}
            };

            axios.get(`${adminApiUrl}/v1/user/profile-img`, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/profile-img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const getProfilePicturePutUrlByUserId = () => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            
            axios.put(`${adminApiUrl}/v1/user/profile-img`, {}, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/profile-img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type BodyUpdateUserRole = {
    id_Role: string
}

export const updateUserRole = (userId: string, body: BodyUpdateUserRole): any => {
    return new Promise((resolve, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/user/${userId}/role`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${userId}/role`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



export const getProfileUsersImages = () => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/user/users-profile-img-not-verified`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/users-profile-img-not-verified`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}


export const verifyUserProfileImage = (userId: string, body: {newVerifiedState: boolean }) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/user/${userId}/verify-profile-img`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${userId}/verify-profile-img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}

export const uploadedUserProfileImage = (userId: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/user/${userId}/uploaded-profile-img`, {}, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${userId}/uploaded-profile-img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}



export const getIdUsersImages = () => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/user/users-id-img-not-verified`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/users-id-img-not-verified`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}


export const verifyUserIdImage = (userId: string, body: {newVerifiedState: boolean }) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/user/${userId}/verify-id-img`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${userId}/verify-id-img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}

export const uploadedUserIdImage = (userId: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/user/${userId}/uploaded-id-img`, {}, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${userId}/uploaded-id-img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}

export const getCertificatesUsersImages = () => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/user/users-certificates-not-verified`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/users-certificates-not-verified`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}

export const verifyUserCerImage = (userId: string, jobId: string, body: {newVerifiedState: boolean }) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, }
            };

            axios.patch(`${adminApiUrl}/v1/user/${userId}/verify-certificate-img/${jobId}`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${userId}/verify-certificate-img/${jobId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}

export type UserValidData = {
    mail?: 1,
    phoneNumber?: 1,
    gimScore?: 1,
    gimmerScore?: 1,
    firstName?: 1,
    lastName?: 1,
}

export const getUserDataById = (id: string,  params: UserValidData) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params
            };

            axios.get(`${adminApiUrl}/v1/user/${id}/name`, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${id}/name`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}


export const getCertificateImgByJobId = (userid: string, jobid: string) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { userid, jobid }
            };

            axios.get(`${adminApiUrl}/v1/user/certificate-img`, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/certificate-img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export type FileID = "Front_id" | "Back_id" | "Video_id"

export const getIdImgByUserId = (userid: string, type: FileID) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { userid, type }
            };

            axios.get(`${adminApiUrl}/v1/user/id-img`, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/id-img get`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const putIdImgByUserId = (type: FileID) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { type }
            };

            axios.put(`${adminApiUrl}/v1/user/id-img`, {}, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/id-img put`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export type JobImgSize = "small" | "mid" | "original"

export const getImagesSkillUser = (id: string, type: TypeOffers, jobId: string, size: JobImgSize) => {
    return new Promise((resolve: (value: string[]) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { jobId, type, size }
            };

            axios.get(`${adminApiUrl}/v1/user/${id}/skill-pictures`, config)
            .then((res) => { resolve(res.data.presignedLinks)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/${id}/skill-pictures`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const verifyEmailExists = (email: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        const config: AxiosRequestConfig = { 
            params: { email }
        };

        axios.get(`${adminApiUrl}/v1/user/email/verify-exists`, config)
        .then((res) => {resolve(res.data)})
        .catch((error) => { 
            console.log("Error: ", `${adminApiUrl}/v1/user/email/verify-exists`, error); 
            reject(error) 
        })
    })
} 

export const verifyUserPhoneNumberExists = (phoneNumber: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {

        const config: AxiosRequestConfig = { 
            params: { phoneNumber }
        };
        console.log("phoneNumber", phoneNumber)
        axios.get(`${adminApiUrl}/v1/user/phone-number/verify-exists`, config)
        .then((res) => {resolve(res.data)})
        .catch((error: AxiosError) => { 
            console.log("Error: ", `${adminApiUrl}/v1/user/phone-number/verify-exists`, error); 
            reject(error) 
        })
    })
} 

type EndSignUpBody = {
    firstName: string
    lastName: string
    phoneNumber: string   
}

export const endSignUp = (body: EndSignUpBody) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            
            axios.patch(`${adminApiUrl}/v1/user/end-sign-up`, body, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error endSignUp:", `${adminApiUrl}/v1/user/end-sign-up`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



export const addSkillDB = (body: Job) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            
            axios.post(`${adminApiUrl}/v1/user/skill`, body, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/skill`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateSkillDB = (body: Job) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            
            axios.put(`${adminApiUrl}/v1/user/skill`, body, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/skill`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const deleteSkillDB = (jobId: string) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            
            axios.delete(`${adminApiUrl}/v1/user/skill/${jobId}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/skill`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const getSkilImgPutUrl = ({ type, jobId, imgIndex }: { type: gimgimmer, jobId: string, imgIndex: string }) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.put(`${adminApiUrl}/v1/user/skill/${jobId}/img`, { type, imgIndex }, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/skill/${jobId}/img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const deleteSkilImg = ({ type, jobId, imgIndex }: { type: gimgimmer, jobId: string, imgIndex: string }) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { type, jobId, imgIndex  }
            };
            axios.delete(`${adminApiUrl}/v1/user/skill/${jobId}/img`, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/skill/${jobId}/img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const setNotificationAsViewedDB = (id: string) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/user/notifications/${id}/viewed`, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/notifications/${id}/viewed`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateLanguageWeb = (language: LanguageLabel) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/user/preferences/language`, { language }, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/preferences/language`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateCurrencyWeb = (currency: Currency) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/user/preferences/currency`, { currency }, config)
            .then((res) => {resolve(res.data.presignedS3Url)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/preferences/currency`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
 

export const checkINERequest = (body: Body_INE) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.post(`${adminApiUrl}/v1/user/ine`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/ine`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export type BodyCedula = {
    nombre: string
    paterno: string 
    idCedula: string
}

export const checkCedulaRequest = (body: BodyCedula) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.post(`${adminApiUrl}/v1/user/cedula`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/cedula`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 