import React, { useEffect, useState } from 'react'
import { CategoryJob, JobCategoryJob } from '../../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import "./ModalContentSelectJob.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import { useAppDispatch } from '../../../../../../redux/store/store'
import { IdCategoryJob, JobWithCategoryId, setAddSkill } from '../../../../../../redux/slices/components/SignUp/SignUpSlice'
import { useNavigate } from 'react-router-dom'
import { Coords, DefaultPosition, getLocalitation, requestUserPosition } from '../../../../../../utils/maps/locations'
import { Colors } from '../../../../../../utils/colors/colors'

type ModalContentSelectJobProps = {
    modalCategoryJobs: CategoryJob[]
    jobsAdded: JobWithCategoryId[]
    closeModal: () => void
}

const ModalContentSelectJob = ({ modalCategoryJobs, closeModal, jobsAdded }: ModalContentSelectJobProps) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [address, setAddress] = useState("")


    useEffect(() => {

        const getData = () => {

            requestUserPosition()
            .then((coords: Coords) => {
                getLocalitation(coords.latitude, coords.longitude)
                .then((data) => {
                    setAddress( data.results[0]?.formatted_address )
                }).catch(() => {

                })
            }).catch((error) => {
                console.log("Error: ", error)

            })
        }

        getData()
      
    }, [])

    const addJob = (category: CategoryJob, job: JobCategoryJob) => {

        const obj = {
            categoryId: category.id,
            categoryName: category.categoryName,
            imgKey: job.imgKey,
            isCertifReq: job.isCertifReq,
            isJobSelected: true,
            isUploaded: false,
            isVerified: false,
            jobId: job.id,
            jobName: job.jobName,
            photoKey: category.photoKey,
        }

        requestUserPosition()
        .then((coords: Coords) => {
            dispatch( 
                setAddSkill({
                    ...obj,
                    workPreference: {
                        inside: {
                            address: address,
                            latitude: coords.latitude,
                            longitude: coords.longitude,
                        },
                        outside: {
                            address: address,
                            latitude: coords.latitude,
                            longitude: coords.longitude,
                            radius: 5
                        },
                        remote: true
                    }
                }) 
            )
        }).catch((error) => {
            console.log("Error: ", error)
            dispatch( 
                setAddSkill({
                    ...obj,
                    workPreference: {
                        inside: {
                            address: DefaultPosition.address,
                            latitude: DefaultPosition.latitude,
                            longitude: DefaultPosition.longitude,
                        },
                        outside: {
                            address: DefaultPosition.address,
                            latitude: DefaultPosition.latitude,
                            longitude: DefaultPosition.longitude,
                            radius: 5
                        },
                        remote: true
                    }
                }) 
            )
        }).finally(() => {
            setTimeout(() => {
                closeModal()
                navigate("/gim-skill/select-location")
            }, 10);
        })
        
    }
   
    return (
        <div className="white-bg p-relative border-radius-6" style={{ height: "47vh", width: "39vw", zIndex: 1, overflow: "hidden" }}>
            <div className="aux-scroll">
                {
                    modalCategoryJobs.map((category) => {
                        return(
                            <div
                                key={category.id}
                                className=""
                            >
                                <div className="dark-gray-bg flex flex-jc-sb flex-ai-center width-100" style={{ height: "4.9vh" }}>
                                    <div className=""></div>

                                    <p className="fs-20-vh c-white">{category.categoryName}</p>

                                    <button className="p-6 mr-10" onClick={closeModal}>
                                        <FontAwesomeIcon icon={faTimes} className="c-white fs-22-vh" />
                                    </button>
                                </div>

                                <div className="flex flex-d-column" style={{ padding: "1.8vh 3vw 1.8vh 3vw" }}>
                                    {
                                        category.jobs.map((job: JobCategoryJob) => {
                                            const isJobSelected = jobsAdded.find((j) => j.jobId === job.id)
                                            return(
                                                <div
                                                    key={job.id}
                                                    className="flex flex-ai-center" 
                                                    style={{ 
                                                        marginTop: "1vh", 
                                                        marginBottom: "1vh",
                                                        color: isJobSelected ? Colors.PictonBlue : Colors.TEXT,
                                                        cursor: isJobSelected ? "auto" : "pointer"
                                                     }}
                                                    onClick={isJobSelected ? () => {} : () => addJob(category, job)}
                                                >
                                                    <FontAwesomeIcon icon={faCircleCheck} className="fs-18-vh" />
                                                    <p className="fs-20-vh ml-10">{job.jobName}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo( ModalContentSelectJob )