import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { 
    getUserCredentials,
    adminApiUrl,
} from "../../axiosUtils";

type editMultipleJobsBody = {
    jobsGrouped: {
        id: string,
        active: boolean,
        isCertifReq: boolean,
        isPool: boolean,
        isGimmable: boolean
    }[]
}

export const editMultipleJobs = (categoryId: string, body: editMultipleJobsBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/job-category/${categoryId}/jobs`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/${categoryId}/jobs`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



type deleteMultipleJobsBody = {
    jobsGrouped: { id: string }[]
}

export const deleteMultipleJobs = (categoryId: string, body: deleteMultipleJobsBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                data: body
            };

            axios.delete(`${adminApiUrl}/v1/job-category/${categoryId}/jobs`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category/${categoryId}/jobs`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
