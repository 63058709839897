import React from 'react'
import "./HeaderMarketplace.css"
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAppDispatch } from '../../../redux/store/store'
import { setAddProductModalReducer } from '../../../redux/slices/components/Marketplace/marketplaceDataSlice'

const HeaderMarketplace = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const onAddProduct = () => {
        dispatch( setAddProductModalReducer() )
    }

    return (
        <div className="flex flex-ai-center">
            <p className="title-screen">{t("Marketplace:label_01")}</p>

            <button
                className="flex flex-ai-center pointer picton-blue-bg pr-20 pl-20 border-radius-6"
                style={{ height: "4vh" }}
                onClick={ onAddProduct }
            >
                <FontAwesomeIcon icon={solid("plus")} className="c-white fs-15" />
                <p className="ml-10 c-white fs-12 fw-500">{t("Marketplace:label_02")}</p>
            </button>
        </div>
    )
}

export default React.memo( HeaderMarketplace )