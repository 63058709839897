import React from 'react'
import { DayInputs, JobCategoryJob, NightInputs } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import { getStyles } from '../../../../utils/colors/colors'
import { useTranslation } from 'react-i18next'
import "./LargeTable.css"
import { getPriceData, twoDecimals, twoDecimalsToPercentage } from '../../../../utils/numbers/numbers'

type LargeTableProps = {
    job: JobCategoryJob
    data: DayInputs | NightInputs
    timeAdded: number
    gimStars: number
    gimmerStars: number
    kmCovered: number
    tip: number
}   

const LargeTable = ({ 
    job, 
    timeAdded, 
    data,
    gimStars,
    gimmerStars,
    kmCovered,
    tip 
}: LargeTableProps) => {

    const { t, i18n } = useTranslation()

    const { price, malusGim, malusGimmer, malusShareGim, malusShareGimmer, IVA } = data

    const langLabel = "MXN$"

    const {
        //Gimmer
        transaction_bonusGim_HT,
        transaction_MalusGimmer_HT,
        transaction_GimmerIVA,
        transaction_GimmerTotal_HT,
        transaction_GimmerPaid_TTC,
        //Gimwork
        transaction_bonusGim,
        transaction_bonusGimmer,
        transaction_RMP_TTC,
        transaction_GimworkTotal_TTC,
        transaction_GimworkIVA,
        transaction_GimworkIncome_HT,
        //Gim
        transaction_MalusGim_TTC,
        transaction_bonusGimmer_TTC,
        transaction_GimTotal_HT,
        transaction_GimIVA,
        transaction_GimISR,
        transaction_GimIncome_TVA,
        transaction_ReportGimwork,
        //Tax
        transaction_TaxGimmer,
        transaction_TaxGim,
        transaction_TaxGimwork,
        transaction_TaxTotal,
        //Stripe 
        transaction_StripeGim,
        transaction_StripeGimmer,
        transaction_Stripe_TTC,
        transaction_TVA_Stripe,
        transaction_Stripe_HT,
        transaction_ReportStripe,

        // Gimmer
        extraTime_bonusGim_HT,
        extraTime_MalusGimmer_HT,
        extraTime_GimmerIVA,
        extraTime_GimmerTotal_HT,
        extraTime_GimmerPaid_TTC,
        //Gimwork
        extraTime_bonusGim,
        extraTime_bonusGimmer,
        extraTime_RMP_TTC,
        extraTime_GimworkTotal_TTC,
        extraTime_GimworkIVA,
        extraTime_GimworkIncome_HT,
        //Gim
        extraTime_MalusGim_TTC,
        extraTime_bonusGimmer_TTC,
        extraTime_GimTotal_HT,
        extraTime_GimIVA,
        extraTime_GimISR,
        extraTime_GimIncome_TVA,
        extraTime_ReportGimwork,
        //Tax
        extraTime_TaxGimmer,
        extraTime_TaxGim,
        extraTime_TaxGimwork,
        extraTime_TaxTotal,
        //Stripe
        extraTime_StripeGim,
        extraTime_StripeGimmer,
        extraTime_Stripe_TTC,
        extraTime_TVA_Stripe,
        extraTime_Stripe_HT,
        extraTime_ReportStripe,

        //Gimmer
        transport_GimmerTotal_HT,
        transport_GimmerIVA,
        transport_GimmerPaid_TTC,
        //Gimwork
        transport_Gimwork_RMP_TTC,
        transport_GimworkTotal_TTC,
        transport_GimworkIVA,
        transport_GimworkIncome_HT,
        //Gim
        transport_GimIVA,
        transport_GimISR,
        transport_GimTotal_HT,
        transport_GimIncome_TVA,
        transport_ReportGimwork,
        //Tax
        transport_TaxGimmer,
        transport_TaxGim,
        transport_TaxGimwork,
        transport_TaxTotal,
        //Stripe
        transport_StripeGim,
        transport_StripeGimmer,
        transport_Stripe_TTC,
        transport_TVA_Stripe,
        transport_Stripe_HT,
        transport_ReportStripe,
        

        //Gimmer
        fixe_bonusGim_HT,
        fixe_MalusGimmer_HT,
        fixe_GimmerTotal_HT,
        fixe_GimmerIVA,
        fixe_GimmerPaid_TTC,
        //Gimwork
        fixe_bonusGim,
        fixe_bonusGimmer,
        fixe_RMP_TTC,
        fixe_GimworkTotal_TTC,
        fixe_GimworkIVA,
        fixe_GimworkIncome_HT,
        //Gim
        fixe_MalusGim_TTC,
        fixe_bonusGimmer_TTC,
        fixe_GimTotal_HT,
        fixe_GimIVA,
        fixe_GimISR,
        fixe_GimIncome_TVA,
        fixe_ReportGimwork,
        //Tax
        fixe_TaxGimmer,
        fixe_TaxGim,
        fixe_TaxGimwork,
        fixe_TaxTotal,
        //Stripe
        fixe_StripeGim,
        fixe_StripeGimmer,
        fixe_Stripe_TTC,
        fixe_TVA_Stripe,
        fixe_Stripe_HT,
        fixe_ReportStripe,

        //Gimmer
        tip_GimmerTotal_HT,
        tip_GimmerIVA,
        tip_GimmerPaid_TTC,
        //Gimwork
        tip_RMP_TTC,
        tip_GimworkTotal_TTC,
        tip_GimworkIVA,
        tip_GimworkIncome_HT,
        //Gim
        tip_GimTotal_HT,
        tip_GimIVA,
        tip_GimISR,
        tip_GimIncome_TVA,
        tip_ReportGimwork,
        //Tax
        tip_TaxGimmer,
        tip_TaxGim,
        tip_TaxGimwork,
        tip_TaxTotal,
        //Stripe
        tip_StripeGim,
        tip_StripeGimmer,
        tip_Stripe_TTC,
        tip_TVA_Stripe,
        tip_Stripe_HT,
        tip_ReportStripe,


    } = getPriceData({
        transaction_price: price,
        transaction_malusGim: twoDecimalsToPercentage(malusGim),
        transaction_malusGimmer: twoDecimalsToPercentage(malusGimmer),
        transaction_malusShareGim: twoDecimalsToPercentage(malusShareGim),
        transaction_malusShareGimmer: twoDecimalsToPercentage(malusShareGimmer),
        transaction_IVA: twoDecimalsToPercentage(IVA),
        

        timeTransaction_gimIVA: twoDecimalsToPercentage(data.timeTransaction.gimIVA),
        timeTransaction_gimmerIVA: twoDecimalsToPercentage(data.timeTransaction.gimmerIVA),
        timeTransaction_gimworkIVA: twoDecimalsToPercentage(data.timeTransaction.gimworkIVA),
        timeTransaction_ISR: twoDecimalsToPercentage(data.timeTransaction.ISR),
        timeTransaction_RMP: twoDecimalsToPercentage(data.timeTransaction.RMP),

        transport_price: data.transportCosts?.price ?? 0,               
        transport_gimIVA: twoDecimalsToPercentage(data.transportCosts?.gimIVA),            
        transport_gimmerIVA: twoDecimalsToPercentage(data.transportCosts?.gimmerIVA),           
        transport_gimworkIVA: twoDecimalsToPercentage(data.transportCosts?.gimworkIVA),           
        transport_ISR: twoDecimalsToPercentage(data.transportCosts?.ISR),                  
        transport_RMP: twoDecimalsToPercentage(data.transportCosts?.RMP),

        fixe_price: data.fixe?.price ?? 0,               
        fixe_gimIVA: twoDecimalsToPercentage(data.fixe?.gimIVA),            
        fixe_gimmerIVA: twoDecimalsToPercentage(data.fixe?.gimmerIVA),           
        fixe_gimworkIVA: twoDecimalsToPercentage(data.fixe?.gimworkIVA), 
        fixe_ISR: twoDecimalsToPercentage(data.fixe?.ISR),
        fixe_RMP: twoDecimalsToPercentage(data.fixe?.RMP),
    
        tip_price: tip,               
        tip_gimIVA: twoDecimalsToPercentage(data.tips?.gimIVA),            
        tip_gimmerIVA: twoDecimalsToPercentage(data.tips?.gimmerIVA),           
        tip_gimworkIVA: twoDecimalsToPercentage(data.tips?.gimworkIVA),         
        tip_ISR: twoDecimalsToPercentage(data.tips?.ISR),          
        tip_RMP: twoDecimalsToPercentage(data.tips?.RMP),
        

        gimStars,
        gimmerStars,
        timeAdded,
        kmCovered,
        tip 
    })

    const dataTable: { text: string, class: string}[][] =[
        [ { text: t("ManageJobs:table:label_01"), class: "lt-01" }, { text: `${price} ${langLabel}`, class: "lt-01" },  { text: `${transaction_bonusGim_HT} ${langLabel}`, class: "lt-01" }, ],

        [ { text: t("ManageJobs:table:label_02"), class: "lt-01" }, { text: `${price*timeAdded} ${langLabel}`, class: "lt-01" },  { text: `${extraTime_bonusGim_HT} ${langLabel}`, class: "lt-01" }, ],

        [ { text: t("ManageJobs:table:label_03"), class: "lt-01" } ],

        [ { text: t("ManageJobs:table:label_04"), class: "lt-01" } ],

        [ { text: t("ManageJobs:table:label_05"), class: "lt-01" } ],
    ]



    dataTable[0].push({ text: `${transaction_MalusGimmer_HT} ${langLabel}`, class: "lt-01" },  { text: `${transaction_GimmerTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimmerIVA} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimmerPaid_TTC} ${langLabel}`, class: "lt-02" }, )
    dataTable[0].push({ text: `${transaction_bonusGim} ${langLabel}`, class: "lt-01" },  { text: `${transaction_bonusGimmer} ${langLabel}`, class: "lt-01" }, { text: `${transaction_RMP_TTC} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimworkTotal_TTC} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimworkIVA} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimworkIncome_HT} ${langLabel}`, class: "lt-03" }, )
    dataTable[0].push({ text: `${transaction_MalusGim_TTC} ${langLabel}`, class: "lt-01" },  { text: `${transaction_bonusGimmer_TTC} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimIVA} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimISR} ${langLabel}`, class: "lt-01" }, { text: `${transaction_GimIncome_TVA} ${langLabel}`, class: "lt-01" }, { text: `${transaction_ReportGimwork}%`, class: "lt-04" }, )
    dataTable[0].push({ text: `${transaction_TaxGimmer} ${langLabel}`, class: "lt-05" }, { text: `${transaction_TaxGim} ${langLabel}`, class: "lt-05" }, { text: `${transaction_TaxGimwork} ${langLabel}`, class: "lt-05" }, { text: `${transaction_TaxTotal} ${langLabel}`, class: "lt-05" }, )
    dataTable[0].push({ text: `${transaction_StripeGim} ${langLabel}`, class: "lt-02" }, { text: `${transaction_StripeGimmer} ${langLabel}`, class: "lt-02" }, { text: `${transaction_Stripe_TTC} ${langLabel}`, class: "lt-02" }, { text: `${transaction_TVA_Stripe} ${langLabel}`, class: "lt-02" }, { text: `${transaction_Stripe_HT} ${langLabel}`, class: "lt-02" },{ text: `${transaction_ReportStripe}%`, class: "lt-02" },)


    dataTable[1].push({ text: `${extraTime_MalusGimmer_HT} ${langLabel}`, class: "lt-01" },  { text: `${extraTime_GimmerTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimmerIVA} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimmerPaid_TTC} ${langLabel}`, class: "lt-02" }, )
    dataTable[1].push({ text: `${extraTime_bonusGim} ${langLabel}`, class: "lt-01" },  { text: `${extraTime_bonusGimmer} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_RMP_TTC} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimworkTotal_TTC} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimworkIVA} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimworkIncome_HT} ${langLabel}`, class: "lt-03" }, )
    dataTable[1].push({ text: `${extraTime_MalusGim_TTC} ${langLabel}`, class: "lt-01" },  { text: `${extraTime_bonusGimmer_TTC} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimIVA} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimISR} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_GimIncome_TVA} ${langLabel}`, class: "lt-01" }, { text: `${extraTime_ReportGimwork}%`, class: "lt-04" }, )
    dataTable[1].push({ text: `${extraTime_TaxGimmer} ${langLabel}`, class: "lt-05" }, { text: `${extraTime_TaxGim} ${langLabel}`, class: "lt-05" }, { text: `${extraTime_TaxGimwork} ${langLabel}`, class: "lt-05" }, { text: `${extraTime_TaxTotal} ${langLabel}`, class: "lt-05" }, )
    dataTable[1].push({ text: `${extraTime_StripeGim} ${langLabel}`, class: "lt-02" }, { text: `${extraTime_StripeGimmer} ${langLabel}`, class: "lt-02" }, { text: `${extraTime_Stripe_TTC} ${langLabel}`, class: "lt-02" }, { text: `${extraTime_TVA_Stripe} ${langLabel}`, class: "lt-02" }, { text: `${extraTime_Stripe_HT} ${langLabel}`, class: "lt-02" },{ text: `${extraTime_ReportStripe}%`, class: "lt-02" },)

    if(data.transportCosts){
        const { price } = data.transportCosts

        //Gimmer  Malus-Gimmer  Gimmer-Total    Gimmer-IVA      Gimmer Paid
        dataTable[2].push({ text: `${price*kmCovered} ${langLabel}`, class: "lt-01" },  { text: `-`, class: "lt-01" }, { text: `-`, class: "lt-01" },  { text: `${transport_GimmerTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${transport_GimmerIVA} ${langLabel}`, class: "lt-01" }, { text: `${transport_GimmerPaid_TTC} ${langLabel}`, class: "lt-02" }, )
        dataTable[2].push({ text: `-`, class: "lt-01" },  { text: `-`, class: "lt-01" }, { text: `${transport_Gimwork_RMP_TTC} ${langLabel}`, class: "lt-01" }, { text: `${transport_GimworkTotal_TTC} ${langLabel}`, class: "lt-01" }, { text: `${transport_GimworkIVA} ${langLabel}`, class: "lt-01" }, { text: `${transport_GimworkIncome_HT} ${langLabel}`, class: "lt-03" }, )
        dataTable[2].push({ text: `-`, class: "lt-01" },  { text: `-`, class: "lt-01" }, { text: `${transport_GimTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${transport_GimIVA} ${langLabel}`, class: "lt-01" }, { text: `${transport_GimISR} ${langLabel}`, class: "lt-01" }, { text: `${transport_GimIncome_TVA} ${langLabel}`, class: "lt-01" }, { text: `${transport_ReportGimwork}%`, class: "lt-04" }, )
        dataTable[2].push({ text: `${transport_TaxGimmer} ${langLabel}`, class: "lt-05" }, { text: `${transport_TaxGim} ${langLabel}`, class: "lt-05" }, { text: `${transport_TaxGimwork} ${langLabel}`, class: "lt-05" }, { text: `${transport_TaxTotal} ${langLabel}`, class: "lt-05" }, )
        dataTable[2].push({ text: `${transport_StripeGim} ${langLabel}`, class: "lt-02" }, { text: `${transport_StripeGimmer} ${langLabel}`, class: "lt-02" }, { text: `${transport_Stripe_TTC} ${langLabel}`, class: "lt-02" }, { text: `${transport_TVA_Stripe} ${langLabel}`, class: "lt-02" }, { text: `${transport_Stripe_HT} ${langLabel}`, class: "lt-02" },{ text: `${transport_ReportStripe}%`, class: "lt-02" },)

    }

    if(data.fixe){
        const { price } = data.fixe

        dataTable[3].push({ text: `${price} ${langLabel}`, class: "lt-01" }, { text: `${fixe_bonusGim_HT} ${langLabel}`, class: "lt-01" }, { text: `${fixe_MalusGimmer_HT} ${langLabel}`, class: "lt-01" },  { text: `${fixe_GimmerTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimmerIVA} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimmerPaid_TTC} ${langLabel}`, class: "lt-02" }, )
        dataTable[3].push({ text: `${fixe_bonusGim} ${langLabel}`, class: "lt-01" },  { text: `${fixe_bonusGimmer} ${langLabel}`, class: "lt-01" }, { text: `${fixe_RMP_TTC} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimworkTotal_TTC} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimworkIVA} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimworkIncome_HT} ${langLabel}`, class: "lt-03" }, )
        dataTable[3].push({ text: `${fixe_MalusGim_TTC} ${langLabel}`, class: "lt-01" },  { text: `${fixe_bonusGimmer_TTC} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimIVA} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimISR} ${langLabel}`, class: "lt-01" }, { text: `${fixe_GimIncome_TVA} ${langLabel}`, class: "lt-01" }, { text: `${fixe_ReportGimwork}%`, class: "lt-04" }, )
        dataTable[3].push({ text: `${fixe_TaxGimmer} ${langLabel}`, class: "lt-05" }, { text: `${fixe_TaxGim} ${langLabel}`, class: "lt-05" }, { text: `${fixe_TaxGimwork} ${langLabel}`, class: "lt-05" }, { text: `${fixe_TaxTotal} ${langLabel}`, class: "lt-05" }, )
        dataTable[3].push({ text: `${fixe_StripeGim} ${langLabel}`, class: "lt-02" }, { text: `${fixe_StripeGimmer} ${langLabel}`, class: "lt-02" }, { text: `${fixe_Stripe_TTC} ${langLabel}`, class: "lt-02" }, { text: `${fixe_TVA_Stripe} ${langLabel}`, class: "lt-02" }, { text: `${fixe_Stripe_HT} ${langLabel}`, class: "lt-02" },{ text: `${fixe_ReportStripe}%`, class: "lt-02" },)

    }
  
    if(data.tips){

        dataTable[4].push({ text: `${tip} ${langLabel}`, class: "lt-01" }, { text: `-`, class: "lt-01" }, { text: `-`, class: "lt-01" },  { text: `${tip_GimmerTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${tip_GimmerIVA} ${langLabel}`, class: "lt-01" }, { text: `${tip_GimmerPaid_TTC} ${langLabel}`, class: "lt-02" }, )
        dataTable[4].push({ text: `-`, class: "lt-01" },  { text: `-`, class: "lt-01" }, { text: `${tip_RMP_TTC} ${langLabel}`, class: "lt-01" }, { text: `${tip_GimworkTotal_TTC} ${langLabel}`, class: "lt-01" }, { text: `${tip_GimworkIVA} ${langLabel}`, class: "lt-01" }, { text: `${tip_GimworkIncome_HT} ${langLabel}`, class: "lt-03" }, )
        dataTable[4].push({ text: `-`, class: "lt-01" },  { text: `-`, class: "lt-01" }, { text: `${tip_GimTotal_HT} ${langLabel}`, class: "lt-01" }, { text: `${tip_GimIVA} ${langLabel}`, class: "lt-01" }, { text: `${tip_GimISR} ${langLabel}`, class: "lt-01" }, { text: `${tip_GimIncome_TVA} ${langLabel}`, class: "lt-01" }, { text: `${tip_ReportGimwork}%`, class: "lt-04" }, )
        dataTable[4].push({ text: `${tip_TaxGimmer} ${langLabel}`, class: "lt-05" }, { text: `${tip_TaxGim} ${langLabel}`, class: "lt-05" }, { text: `${tip_TaxGimwork} ${langLabel}`, class: "lt-05" }, { text: `${tip_TaxTotal} ${langLabel}`, class: "lt-05" }, )
        dataTable[4].push({ text: `${tip_StripeGim} ${langLabel}`, class: "lt-02" }, { text: `${tip_StripeGimmer} ${langLabel}`, class: "lt-02" }, { text: `${tip_Stripe_TTC} ${langLabel}`, class: "lt-02" }, { text: `${tip_TVA_Stripe} ${langLabel}`, class: "lt-02" }, { text: `${tip_Stripe_HT} ${langLabel}`, class: "lt-02" },{ text: `${tip_ReportStripe}%`, class: "lt-02" },)

    }

    const heads = [ t("ManageJobs:table:label_06") ?? "", "Bonus Gim", "Malus Gimmer", `Gimmer Total HT`, "Gimmer IVA", "Gimmer Paid TTC", "Malus Gim", "Malus Gimmer", "RMP", "Gimwork Total TTC", "Gimwork IVA", "Gimwork Income HT", "Malus Gim TTC", "Bonus Gimmer TTC", "Gim Total HT", "Gim IVA", "Gim ISR", "Gim Income + TVA", "Report Gimwork", "Tax Gimmer", "Tax Gim", "Tax Gimwork", "Tax Total", "Stripe Gim", "Stripe Gimmer", "Stripe TTC", "TVA Stripe", "Stripe HT", "Report Stripe" ]

    return (
        <div className="mt-20" style={{ overflowX: "scroll" }}>
            <table className="fs-16-vh" style={{ minWidth: "4800px" }}>

                <tbody className="large-table-body">
                    <tr>
                        <th className=""></th>
                        { 
                            heads.map((str: string) => {
                                return (
                                    <th key={str} className="th-large-table">{str}</th>
                                )
                            })
                        }
                    </tr>
                    {
                        dataTable.map((row, index) => {
                            return(
                                <tr key={index}>
                                    {
                                        row.map((value, index) => {
                                            
                                            if(index === 0){
                                                return(
                                                    <th 
                                                        key={index} 
                                                        className={`tr-large-table ${value.class}`} 
                                                        scope="row"
                                                    >
                                                        {value.text}
                                                    </th> 
                                                )
                                            }
                                            return(
                                                <td 
                                                    key={index} 
                                                    className={`text-align-center ${value.class}`} 
                                                >
                                                    {value.text}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default React.memo( LargeTable )