import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store/store';
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { setAddGeneralInformationModalState } from '../../../redux/slices/components/GeneralInformation/GeneralInformationSlice';

const HeaderGeneralInformation = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const accessCreate = useCheckAccess({ id_Category: "General_Information", id_Policy: "Shop_Fees", id_Action: "Create" })

    const handleAddCategory = () => {
        dispatch( setAddGeneralInformationModalState() )
    }

    return (
        <div className="flex flex-jc-sb flex-ai-center pb-6">
            <p className="title-screen">{t("General:label_255")}</p>
            {
                accessCreate &&
                <div className="flex flex-ai-center flex-1">
                    <div>
                        <button 
                            className="btn-beside-title-screen"
                            onClick={handleAddCategory}
                        >
                            <FontAwesomeIcon icon={regular("plus")} className="fs-18" />
                            <p className="text-btn-title-screen">{t("General:label_256")}</p>
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default React.memo( HeaderGeneralInformation )