import React from 'react'
import MyCheckbox from '../../../../UIcomponents/MyCheckbox/MyCheckbox'
import "./TitleCheckboxInputs.css"

type TitleCheckboxInputsProps = {
    title:              string
    color:              string
    valueCheckBox?:     boolean
    onChangeCheckbox?:  (value: boolean) => void
}

const TitleCheckboxInputs = ({ title, color, valueCheckBox, onChangeCheckbox }: TitleCheckboxInputsProps) => {
    return (
        <>
            <div className="label-checkbox flex flex-ai-center gallery-bg" style={{ backgroundColor: color }}>
                {
                    valueCheckBox !== undefined && onChangeCheckbox &&
                    <div className="mr-10">
                        <MyCheckbox 
                            onChange={() => onChangeCheckbox(!valueCheckBox)}
                            value={valueCheckBox}
                            size="1.3vh"
                        />
                    </div>
                }

                <p>{title}</p>
            </div>
            { valueCheckBox !== undefined && !valueCheckBox && <div className="background-disable-inputs"></div> }
        </>
    )
}

export default React.memo(TitleCheckboxInputs)