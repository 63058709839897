import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TypeOffers, resetDetailedOfferReducer } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice';
import { GetOfferByIdThunk, GetOfferByUsersThunk, GetOffersThunk, removeDeletedOfferReducer } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { deleteOffer } from '../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions';
import { isUUID } from '../../../../utils/verifications/uuid/uuid';
import LoadingSpinner from '../../../LoadingSpinner';
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import DescriptionModal from '../../../UIcomponents/Modals/DescriptionModal/DescriptionModal';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import "./DeleteOfferModal.css"

interface DeleteOfferModalProps {
    offerId: string,
    jobName: string,
    type: TypeOffers,
    setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
}



const DeleteOfferModal = ({ offerId, jobName, type, setShowDeleteModal }: DeleteOfferModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const [selectValue, setSelectValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false)

    const { offersIds, limit, page, indexTab, stringToSearch  } = useSelector((state: RootState) => state.manageOffersData)

    const handleClickOutsideModal = (e: any) => {
      const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
      if(!clickOutsideModal && e.target.id === "darkened-background-modal")
          setShowDeleteModal(false)
    }
  
    const handleSubmit = (e: any) => {
        e.preventDefault()
        setLoading(true)
        deleteOffer(offerId, type)
        .then((res) => {
            let lastKey = ""
            if(page>1)
                lastKey = offersIds[((page - 1) * limit) - 1]

            if(indexTab > -1)
                dispatch(GetOffersThunk({ type, page, limit, bringTotal: true}))
            else{
                if(isUUID(stringToSearch)){
                    dispatch(GetOfferByIdThunk({ id: stringToSearch }))
                }else{
                  dispatch(GetOfferByUsersThunk({ page: 1, limit,  bringTotal: true, stringToSearch: stringToSearch }))
                }
            }
            setShowDeleteModal(false)
            dispatch(resetDetailedOfferReducer())
        }).catch((error) => {
            console.log("error", error)
        }).finally(() => { setLoading(false) })
    }
    
    return (

        <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
            <div id={"background-aux-modal"}>
                <div className="blank-wrapper-modals">
                    <TitleModal title={t("General:label_010")} />
                    <DescriptionModal description={`${t("General:label_011")} ${jobName}?`} />
                    
                    <form onSubmit={handleSubmit} >

                        <p className="fs-15 fw-500 mt-16">{t("General:label_012")}</p>

                        <select
                            name="countryName"
                            className="input-modal mb-30"
                            style={{ width: "70%" }}
                        >

                            <option value="Unsuitable images"className="black">
                                {t("General:label_013")}
                            </option>

                            <option value="Other"className="black">
                                {t("General:label_014")}
                            </option>

                        </select>

                        <div className="flex flex-jc-end mt-100" >
                            <button onClick={() => setShowDeleteModal(false)} type="button" className="cancel-btn-modal pointer">
                                {t("General:label_015")}
                            </button>
                            <ConfirmButton text={t("General:label_016")} />
                        </div>
                    </form>
                    
                </div>
            </div>
            { loading && <LoadingSpinner fullPage={true} /> }
        </div>
    )
}

export default DeleteOfferModal