import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store/store'
import { useTranslation } from 'react-i18next'
import "./InformationTab.css"
import WorkPreferenceIcon from '../../../../UIcomponents/WorkPreferenceIcon/WorkPreferenceIcon'
import CardsUserReport from './CardsUserReport/CardsUserReport'
import BanUserFields from './BanUserFields/BanUserFields'
import ButtonsMoveReport from './ButtonsMoveReport/ButtonsMoveReport'
import TransactionInfo from './TransactionInfo/TransactionInfo'


type InformationTabProps = {

}

const InformationTab = ({ }: InformationTabProps) => {

    const { t } = useTranslation()
    
    const description = useSelector((state: RootState) => state.manageUserReports.userReport.description)
    const banUserButtonPressed = useSelector((state: RootState) => state.manageUserReports.banUserButtonPressed)
    const indexTab = useSelector((state: RootState) => state.manageUserReports.indexTab)

    return (
        <div className={`p-absolute width-100 white-bg height-100 ${indexTab === 1 ? "show-tab" : "hide-tab"}`}  >

            <CardsUserReport />

            <TransactionInfo />

            <div className="mt-16">
                <p className="fs-13 fw-700 mt-20 mb-8">{t("General:label_173") + ":"}</p>
                <textarea rows={3} readOnly className="width-100 fs-13 p-8 border-radius-3 resize-none" style={{ borderColor: "black", lineHeight: "2rem"}} value={ description } />
            </div>

            <ButtonsMoveReport />
            
            { banUserButtonPressed && <BanUserFields /> }

        </div>
    )
}

export default React.memo( InformationTab )