import React, { ChangeEvent } from 'react'
import "./RowInputs.css"
import MyCheckbox from '../../../../UIcomponents/MyCheckbox/MyCheckbox'
import GeneralInputTitle from '../../../../UIcomponents/GeneralInputTitle/GeneralInputTitle'
import { ValuesForm } from '../../../../../utils/verifications/Forms'

export type DataInputRowJob = { 
    name:           string
    placeholder:    string 
    width:          string 
    type:           string 
    step?:          string | number 
}

type RowInputsProps = {
    color: string
    inputValues: ValuesForm
    inputData: DataInputRowJob[]
    onChange: (newValue: ChangeEvent<HTMLInputElement>) => void
    onFocus: (name: string) => void
    children?: React.ReactNode
}

const RowInputs = ({ color, inputValues, inputData, onChange, onFocus, children }: RowInputsProps) => {
    return (
        <div className="con-inputs-job-prices width-100 gallery-bg mt-22" style={{ backgroundColor: color }}>
            {
                children && children
            }
            {
                inputData.map(({ name, placeholder, width, type, step }) => {
                    return(
                        <GeneralInputTitle 
                            key={name}
                            title={placeholder}
                            name={name}
                            value={inputValues[name].value}
                            onChange={onChange}
                            onFocus={onFocus}
                            width={width}
                            type={type}
                            step={step}
                            placeholder={placeholder}
                            error={inputValues[name].error}
                            labelBgColor={color}
                        />
                    )
                })
            }


        </div>
    )
}

export default React.memo( RowInputs )