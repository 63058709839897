import React, { useEffect, useState } from 'react'
import CardPricesSimulator, { RowCardPriceSimulator } from './CardPricesSimulator/CardPricesSimulator'
import { JobCategoryJob } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import "./CardsPricesSimulator.css"
import { useTranslation } from 'react-i18next'

export type CardPriceSimulator = { title: string, color: string, data: RowCardPriceSimulator[] }

type CardsPricesSimulatorProps = {
    job: JobCategoryJob
}

const CardsPricesSimulator = ({ job }: CardsPricesSimulatorProps) => {

    const { t } = useTranslation()

    const [data, setData] = useState<CardPriceSimulator[]>([])

    useEffect(() => {
      
        const data: CardPriceSimulator[] = [
            { 
                title: t("ManageJobs:label_01"), 
                color: "#EFEFEF", 
                data: [
                    { name: t("ManageJobs:label_08"), value: `${job.pricingInformation.day.price} MXN$`},
                    { name: "Malus Gim", value: `${job.pricingInformation.day.malusGim}%` },
                    { name: "Malus Gimmer", value: `${job.pricingInformation.day.malusGimmer}%` },
                    { name: "Share Gim", value: `${job.pricingInformation.day.malusShareGim}%` },
                    { name: "Share Gimmer", value: `${job.pricingInformation.day.malusShareGimmer}%` },
                    { name: "IVA", value: `${job.pricingInformation.day.IVA}%` },
                ]  
            }
        ]

        if(job.pricingInformation.night?.nightHours){
            data.push({ 
                title: t("ManageJobs:label_02"), 
                color: "#F8E2D7", 
                data: [
                    { name: t("ManageJobs:label_06"), value: job.pricingInformation.night.nightHours.startTime },
                    { name: t("ManageJobs:label_07"), value: job.pricingInformation.night.nightHours.endTime },
                ]  
            })
        }

        if(job.pricingInformation.day.fixe){
            data.push({ 
                title: "Fixe", 
                color: "#F8D7DA", 
                data: [
                    { name: t("ManageJobs:label_08"), value: `${job.pricingInformation.day.fixe.price} MXN$` },
                    { name: "Gim IVA", value: `${job.pricingInformation.day.fixe.gimIVA}%` },
                    { name: "Gimmer IVA", value: `${job.pricingInformation.day.fixe.gimmerIVA}%` },
                    { name: "Gimwork IVA", value: `${job.pricingInformation.day.fixe.gimworkIVA}%` },
                    { name: "RMP", value: `${job.pricingInformation.day.fixe.RMP}%` },
                    { name: "ISR", value: `${job.pricingInformation.day.fixe.ISR}%` },
                ]  
            })
        }

        if(job.pricingInformation.day.timeTransaction){
            data.push({ 
                title: t("ManageJobs:label_04"), 
                color: "#F3FAFE", 
                data: [
                    { name: "Gim IVA", value: `${job.pricingInformation.day.timeTransaction.gimIVA}%` },
                    { name: "Gimmer IVA", value: `${job.pricingInformation.day.timeTransaction.gimmerIVA}%` },
                    { name: "Gimwork IVA", value: `${job.pricingInformation.day.timeTransaction.gimworkIVA}%` },
                    { name: "RMP", value: `${job.pricingInformation.day.timeTransaction.RMP}%` },
                    { name: "ISR", value: `${job.pricingInformation.day.timeTransaction.ISR}%` },
                ]  
            })
        }

        if(job.pricingInformation.day.transportCosts){
            data.push({ 
                title: t("ManageJobs:label_05"), 
                color: "#FFF3CD", 
                data: [
                    { name: t("ManageJobs:label_09"), value: `${job.pricingInformation.day.transportCosts.price}$/km` },
                    { name: "Gim IVA", value: `${job.pricingInformation.day.transportCosts.gimIVA}%` },
                    { name: "Gimmer IVA", value: `${job.pricingInformation.day.transportCosts.gimmerIVA}%` },
                    { name: "Gimwork IVA", value: `${job.pricingInformation.day.transportCosts.gimworkIVA}%` },
                    { name: "RMP", value: `${job.pricingInformation.day.transportCosts.RMP}%` },
                    { name: "ISR", value: `${job.pricingInformation.day.transportCosts.ISR}%` },
                ]  
            })
        }

        if(job.pricingInformation.day.tips){
            data.push({ 
                title: t("ManageJobs:table:label_05"), 
                color: "#D4EDDA", 
                data: [
                    { name: t("ManageJobs:label_10"), value: `${job.pricingInformation.day.tips.tip}%` },
                    { name: "Gim IVA", value: `${job.pricingInformation.day.tips.gimIVA}%` },
                    { name: "Gimmer IVA", value: `${job.pricingInformation.day.tips.gimmerIVA}%` },
                    { name: "Gimwork IVA", value: `${job.pricingInformation.day.tips.gimworkIVA}%` },
                    { name: "RMP", value: `${job.pricingInformation.day.tips.RMP}%` },
                    { name: "ISR", value: `${job.pricingInformation.day.tips.ISR}%` },
                ]  
            })
        }

        setData(data)

    }, [job])
    

    return (
        <div className="flex con-cards-price-simulator">
            {
                data.map(({ title, color, data }) => {
                    return(
                        <CardPricesSimulator 
                            key={title}
                            title={title}
                            color={color}
                            data={data}
                        />
                    )
                })
            }
        </div>
    )
}

export default React.memo( CardsPricesSimulator )