import { useEffect } from 'react';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import HeaderManageJobs from '../../../../components/ManagerJobs/HeaderManageJobs/HeaderManageJobs'
import JobDropdowns from '../../../../components/ManagerJobs/JobDropdowns/JobDropdowns'
import "./ManageJobsScreen.css";
import "../../../../components/UIcomponents/Modals/Modals.css";
import ManagerJobsModals from '../../../../components/ManagerJobs/ManagerJobsModals/ManagerJobsModals';
import { resetManagerJobsGroupedDataSlice } from '../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice';
import React from 'react';

const ManageJobsScreen = React.memo(() => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetManagerJobsGroupedDataSlice())
        }
    }, [])

    return (
        <div className="container-manage-jobs">
            <HeaderManageJobs />
            <JobDropdowns />
            <ManagerJobsModals />
        </div>
    )
})

export default ManageJobsScreen