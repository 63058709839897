import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import { Colors } from '../../../../utils/colors/colors'

type JobCertifiedLabelProps = {
    jobName: string
    isCertifReq: boolean
    children?: ReactNode 
}

const JobCertifiedLabel = ({ jobName, isCertifReq, children }: JobCertifiedLabelProps) => {
  return (
    <div className="flex flex-ai-center">
        {children}
        {
        isCertifReq 
        ? <div className="flex flex-ai-center">
                <div className=" flex flex-ai-center border-radius-3 pr-12 pl-12" style={{ backgroundColor: Colors.PersianRed, height: "3.9vh",  }}>
                    <p className="fs-20-vh c-white mr-10 mt-2">{jobName}</p>
                    <FontAwesomeIcon icon={faGraduationCap} className="c-white fs-18-vh"  />
                </div>

                <div className="ml-20 center flex-d-column border-radius-3 pr-10 pl-10" style={{ backgroundColor: Colors.MercuryGray, height: "3.6vh",  }}>
                    <p className="fs-12-vh color-text">{"Certified"}</p>
                    <p className="fs-12-vh color-text">{"required"}</p>
                </div>
            </div>
        : <p className="fs-20-vh color-text fw-600">{jobName}</p>
        }
    </div>
  )
}

export default React.memo( JobCertifiedLabel )