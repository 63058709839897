import React, { useCallback, useEffect, useState } from 'react'
import JobLabel from '../../../../components/SignUp/UIComponents/JobLabel/JobCertifiedLabel'
import { useNavigate } from 'react-router-dom'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { useSelector } from 'react-redux'
import ArrowGoBackSignUp from '../../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../../components/UIcomponents/NextButton/NextButton'
import AddPictureRectangle from '../../../../components/SignUp/SkillGim/AddPicturesSkill/AddPictureRectangle/AddPictureRectangle'
import { deleteSkilImg, getImagesSkillUser, getSkilImgPutUrl } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import axios from 'axios'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import moment from 'moment'
import { generateRandom10DigitNumber } from '../../../../utils/numbers/numbers'

export type ImgDataSkill = {
    imgUrl: string
    localImgUrl: string
    imgIndex: string
}

const AddPicturesSkillScreen = () => {

    const navigate = useNavigate()

    const idInputImage = "sign-up-profile-add-picture"

    const userId = useSelector((state: RootState) => state.auth.user.id) 
    const job = useSelector((state: RootState) => state.signUp.currentJob) ?? null

    const [images, setImages] = useState<File[]>([])
    const [imagesUrls, setImagesUrls] = useState<ImgDataSkill[]>([])

    const [error, setError] = useState("")
    const [loadingImage, setLoadingImage] = useState(false)

    const getData = async() => {
        getImagesSkillUser(userId, "Job", job?.jobId ?? "", "small")
        .then((urls) => {
            const newUrls: ImgDataSkill[] = urls.map((url) => ({ imgUrl: url, localImgUrl: "", imgIndex: "" }) )
            setImagesUrls(newUrls)
        }).catch(() => {

        })
    }

    useEffect(() => {
        
        getData()

    }, [])
    

    const onNext = useCallback(
        () => {
            navigate("/gim-skill/view-skills")
        },
        [],
    )

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const onAddPicture = useCallback(
        () => {
            document.getElementById(idInputImage)?.click()
        },
        [],
    )

    const uploadImage = (file: File, imgIndex: string) => {
        return new Promise((resolve: (value: {imgUrl: string, imgIndex: string, localImgUrl: string }) => void, reject) => {
            getSkilImgPutUrl({ jobId: job?.jobId ?? "", type: "gim", imgIndex })
            .then((url) => {

                const options = { headers: { 'Content-Type': 'image/jpeg' } };
                axios.put(url, file, options)
                .then(() => {

                    const fReader = new FileReader();
                    fReader.readAsDataURL(file);
                    fReader.onloadend = function(event){
                        if(typeof event.target?.result === "string"){
                            //setImagesUrls([ ...imagesUrls, event.target?.result])
                            resolve({ imgUrl: "", imgIndex, localImgUrl: event.target?.result })
                        }
                    }
                }).catch(() => {
                    setError("Error loading Image")
                    reject(error)
                })


            }).catch(() => {
                setError("Error loading image")
                setLoadingImage(false)
            })
        })
    }


    const setImageUploaded = async() => {

        const filesImages: FileList | null = (document.getElementById(idInputImage) as HTMLInputElement)?.files
        if(filesImages?.length)
        {

            if(error)
                setError("")

            const promises = []
            const files = []
            for(const file of filesImages){
                const imgIndex = generateRandom10DigitNumber().toString()
                files.push(file)
                promises.push( uploadImage(file, imgIndex) )
            }

            setLoadingImage(true)

            const res = await Promise.all( promises )

            const imgUrls = res.map((obj) => obj)

            setImagesUrls([...imagesUrls, ...imgUrls])
            setImages([...images, ...files])

            setTimeout(() => {
                setLoadingImage(false)
            }, 500);
        }
    }


    const onRemove = (index: number, img: ImgDataSkill) => {

        let imgIndex = ""

        if(img.imgUrl){
            const decodedURL = decodeURIComponent(img.imgUrl);
            const auxUrl = decodedURL.split("??")[0]
            imgIndex = auxUrl.split("/").reverse()[0]
        }else{
            imgIndex = img.imgIndex
        }




        /*
        const str = decodedURL;
        const regex = /\/([^?]+)\?\?/;
        const match = str.match(regex);
*/
        /*
            /: Matches a literal forward slash character.
            \/: Escapes the forward slash because it has a special meaning in regular expressions.
            (: Starts a capturing group.
            [^?]+: Matches one or more characters (except "?") using the + quantifier and the negation character set [^].
            ): Ends the capturing group.
            \?: Matches a literal question mark character.
            \?: Escapes the question mark because it has a special meaning in regular expressions.
        */
            

        if(error)
            setError("")

        setLoadingImage(true)
        deleteSkilImg({ jobId: job?.jobId ?? "", type: "gim", imgIndex })
        .then(() => {
            setImages( images.filter((image, i) => i !== index) )
            setImagesUrls( imagesUrls.filter((image, i) => i !== index) )
        }).catch(() => {
            setError("Error removing image")
        }).finally(() => {
            setLoadingImage(false)
        })
    }

    useEffect(() => {
        
        if(!job)
            navigate("/gim-skill/select-skill")
    }, [])
    
    

    if(!job){
        return <></>
    }

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ paddingTop: "10vh" }}>
            <div className="flex flex-jc-sb flex-ai-center">
                <p className="fs-36-vh c-text">{"Add pictures to promote yourself"}</p>
                <JobLabel jobName={job.jobName} isCertifReq={job.isCertifReq} />
            </div>

            <input 
                id={idInputImage} 
                className="display-none" 
                type="file" 
                accept="image/jpeg"
                onChange={setImageUploaded}
                multiple={true}
            />

            <div className="flex flex-wrap" style={{ minHeight: "36vh" }}>
                {
                    imagesUrls.map((data, index) => {
                        return(
                            <AddPictureRectangle key={index} index={index} onRemove={onRemove} onPress={() => {}} img={data} />
                        )
                    })
                }
                { !loadingImage && <AddPictureRectangle index={-1} onPress={onAddPicture} onRemove={() => {}} img={{} as ImgDataSkill}  /> }
            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={imagesUrls.length > 0 ? "Next" : "Skip"} 
                />
            </div>

            { loadingImage && <LoadingSpinner fullPage={true} /> }
        </div>
    )
}

export default AddPicturesSkillScreen