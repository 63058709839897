import React, { useState } from 'react'
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import { MyYup } from '../../../../../utils/verifications/myYup'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../redux/store/store'
import LoadingSpinner from '../../../../LoadingSpinner'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import { getUrlPutGeneralInformation } from '../../../../../utils/requestsUtils/functionsRequests/generalInformation/generalInformationFunction'
import axios from 'axios'
import { setAddGeneralInformationState } from '../../../../../redux/slices/components/GeneralInformation/GeneralInformationSlice'

type FormAddGeneralInformationModalProps = {
    closeModal: () => void
}

const FormAddGeneralInformationModal = ({ closeModal }: FormAddGeneralInformationModalProps) => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        countryName: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().justLetters()},
        countryTax: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber()},
        gimworkFee: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber()},
    });  




    const handleSubmit = async(e: any) => {

        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return
        
        setLoading(true)
        try {

            const url = await getUrlPutGeneralInformation(inputValues["countryName"].value)
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const jsonFile = JSON.stringify({
                countryTax: inputValues["countryTax"].value,
                gimworkFee: inputValues["gimworkFee"].value
            })
            await axios.put(url, jsonFile, config)
            dispatch(setAddGeneralInformationState({
                countryName: inputValues["countryName"].value,
                countryTax: inputValues["countryTax"].value,
                gimworkFee: inputValues["gimworkFee"].value
            }))
            closeModal()
        } catch (error) {
            console.log("Error", error)
        }

        setLoading(false)

    }

    const checkErrors = (e: any) => {
        const value = e.target.value
        const result = inputValues[e.target.name].checkValue(value)
        if(result.errorMsg)
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: result.errorMsg}})
        else
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }})
    }
    

    const checkErrorsWhenSubmit = (nameInput: string): boolean => {
        const value = inputValues[nameInput].value
        const result = inputValues[nameInput].checkValue(value)
        if(result.errorMsg){
            inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
            return true
        }else{
            inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
            return false
        }
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-d-column height-100 flex-jc-sb" >


            <div className="flex flex-d-column mt-10 mb-10">
                <label className="fs-14 black fw-600" htmlFor="categoryName">{t("General:label_117")} <span className="c-asterik">*</span></label>
                <div className="con-input">
                    <select
                        name="countryName"
                        className="input-modal"
                        onChange={(e: any) => {
                            checkErrors(e)
                        }}
                        value={inputValues["countryName"].value}
                    >
                        <option value="">--{t("General:label_118")}--</option>
                        {
                            ["Mexico", "France"].map((countryName: string, index: number) => (
                                    <option 
                                        key={index}
                                        value={countryName}
                                    >
                                        { countryName }
                                    </option>

                            ))
                        }
                    </select>
                    { inputValues["countryName"].error && (
                        <p className="error-input-text">{inputValues["countryName"].error  || ""}</p>
                    )}
                </div>
            </div>

            <div className="flex">
                <div className="con-input padding-to-show-error flex-1">
                    <input
                        id="countryTax"
                        name="countryTax"
                        type="number"
                        placeholder={"Country tax"}
                        className="input-modal"
                        onChange={(e: any) => {
                            checkErrors(e)
                        }}
                        value={inputValues["countryTax"].value}
                        onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                        min="0"
                        max="100"
                    />
                    { inputValues["countryTax"].error && (
                        <p className="error-input-text">{inputValues["countryTax"].error  || ""}</p>
                    )}
                </div>

                <div className="con-input padding-to-show-error flex-1 ml-28">
                    <input
                        id="gimworkFee"
                        name="gimworkFee"
                        type="number"
                        placeholder={"Gimwork"}
                        className="input-modal"
                        onChange={(e: any) => {
                            checkErrors(e)
                        }}
                        value={inputValues["gimworkFee"].value}
                        onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                        min="0"
                        max="100"
                    />
                    { inputValues["gimworkFee"].error && (
                        <p className="error-input-text">{inputValues["gimworkFee"].error  || ""}</p>
                    )}
                </div>
            </div>


            <div className="flex flex-d-column">
                <ConfirmButton text={t("General:label_131")} width="100%" />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
        </form>
    )
}

export default React.memo( FormAddGeneralInformationModal )