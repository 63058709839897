import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getGeneralInformation } from '../../../../utils/requestsUtils/functionsRequests/generalInformation/generalInformationFunction'
import { getS3GeneralInformationUri } from '../../../../utils/staticVariables/staticVariables'
import axios from 'axios'

type Country = "Mexico" | "France"
export type ModalNameGeneralInformation = "AddGeneralInformationModal" | "DeleteGeneralInformationModal" | "EditGeneralInformationModal" | ""


export type GeneralInformationInfo = {
    countryName: string
    gimworkFee: string
    countryTax: string
}


export const getGeneralInformationChunk = createAsyncThunk(
    'generalInformation/getGeneralInformationChunk',
    async (thunkAPI) => {

        try {
            const items = await getGeneralInformation();

            const generalInformationItems: any = []
            const promises: any = []
            items.forEach((item: any) => {
                const country = item.Key.split("/")[1]
                item.country = country
                item.uri = getS3GeneralInformationUri(item.Key)
                generalInformationItems.push(item)
                promises.push(axios.get(item.uri))

            })

            const res = await Promise.all(promises)
            const informations : GeneralInformationInfo[] = res.map(({ data }, index) => {
                return {
                    countryName: generalInformationItems[index].country,
                    gimworkFee: data.gimworkFee,
                    countryTax: data.countryTax
                }
            })

            return informations
        } catch (error) {
            console.log("error", error)
            return [] as GeneralInformationInfo[]
        }

    }
  )


const initialState = {
    modalName: "" as ModalNameGeneralInformation,
    informations: [] as GeneralInformationInfo[],
    AddGeneralInformationModalState: undefined,
    DeleteGeneralInformationModalState: {} as GeneralInformationInfo,
    EditGeneralInformationModal: {} as GeneralInformationInfo,
    loadingContainer: true
};

export const generalInformationSlice = createSlice({
    name: 'generalInformation',
    initialState,
    reducers: {
        setAddGeneralInformationModalState: (state) => {
            state.modalName = "AddGeneralInformationModal"
            return state;
        },
        setDeleteGeneralInformationModalState: (state, action: PayloadAction<GeneralInformationInfo>) => {
            state.modalName = "DeleteGeneralInformationModal"
            state.DeleteGeneralInformationModalState = action.payload;
        },
        setEditGeneralInformationModalState: (state, action: PayloadAction<GeneralInformationInfo>) => {
            state.modalName = "EditGeneralInformationModal";
            state.EditGeneralInformationModal = action.payload
            return state;
        },
        setAddGeneralInformationState: (state, action: PayloadAction<GeneralInformationInfo>) => {
            state.informations.push(action.payload)
            return state;
        },
        setDeleteGeneralInformationState: (state, action: PayloadAction<Country>) => {
            state.informations = state.informations.filter(i => i.countryName !== action.payload)
            return state;
        },
        setEditGeneralInformationState: (state, action: PayloadAction<GeneralInformationInfo>) => {
            state.informations.forEach((i) => {
                if(i.countryName === action.payload.countryName)
                    i = action.payload
            })
            for(let i=0; i <state.informations.length; i++){
                if(state.informations[i].countryName === action.payload.countryName)
                    state.informations[i] = action.payload
            }
            return state;
        },


        resetModalsValueFromGeneralInformationState: (state) => {
            state.modalName = ""
            state.DeleteGeneralInformationModalState= {} as GeneralInformationInfo
            state.EditGeneralInformationModal = {} as GeneralInformationInfo
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getGeneralInformationChunk.fulfilled, (state, action: PayloadAction<GeneralInformationInfo[]>) => {
            state.informations = action.payload
            state.loadingContainer = false
            return state
          }).addCase(getGeneralInformationChunk.pending, (state, action: any) => {
            state.loadingContainer = true
            return state     
          }).addCase(getGeneralInformationChunk.rejected, (state, action: any) => {
            state.loadingContainer = false
            return state
          })
    }
});

// Action creators are generated for each case reducer function
export const { 
    setAddGeneralInformationModalState,
    setDeleteGeneralInformationModalState,
    setEditGeneralInformationModalState,
    setAddGeneralInformationState,
    setDeleteGeneralInformationState,
    setEditGeneralInformationState,
    resetModalsValueFromGeneralInformationState
} = generalInformationSlice.actions

export default generalInformationSlice.reducer