import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getCreationDate, getWhenDate } from '../../../../utils/dates/dates'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { GimGimmer, getOffer, getOffers, getOffersByUsers } from '../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import { UserInfoCache } from '../../../../utils/staticVariables/staticVariables'


export type TypeOffers = "Task" | "Job"

export type ExtraDataOffer = {
    type: TypeOffers,
    //LastEvaluatedKey?: string,
    page: number,
    limit: number,
    bringTotal: boolean
}


export type DetailsSchedule = {
    id: string,
    start: string,
    end: string
}

export type InsideWorkPreference = {
    address: string, 
    longitude: number, 
    latitude: number 
}

export type OutsideWorkPreference = {
    address: string,
    radius: number,
    longitude: number,
    latitude: number
}

export type WorkPreference = {
    inside: InsideWorkPreference,
    remote: boolean,
    outside: OutsideWorkPreference
}

export interface Offer {
    categoryName: string,
    isJobSelected: boolean,
    detailsSchedule: DetailsSchedule[],
    recurrentSchedule: any,
    timestamp: string,
    timestampMilliseconds: number,
    workPreference: WorkPreference,
    jobId: string,
    countryName: string,
    categoryLogo: string,
    jobName: string,
    isVerified: boolean,
    isActive: boolean,
    userId: string,
    id: string,
    imgKey: string,
    isCertifReq: boolean,
    isUploaded: boolean,
    type: TypeOffers,
    creationDate?: string,
    whenDate: { date: string, hour: string },
    seen: boolean,
    userName?: string
}
  
const addRemainingFieldsOffer = (offer: Offer) => {
    offer.creationDate = getCreationDate(offer.timestamp)
    offer.whenDate = getWhenDate(offer.detailsSchedule.length ? offer.detailsSchedule[0] : {id: "", start: "", end: "" })
    offer.seen = offer.seen ?? false
    offer.timestampMilliseconds = new Date(offer.creationDate).getTime()
    //@ts-expect-error
    offer.type = offer.offertype === "Gim" ? "Task" : "Job"
}

const changeNameToUppercase = (offer: Offer) => {
    //@ts-expect-error
    offer.detailsSchedule = offer.detailsschedule
    //@ts-expect-error
    delete offer["detailsschedule"]

    //@ts-expect-error
    offer.workPreference = offer.workpreference
    //@ts-expect-error
    delete offer["workpreference"]

    //@ts-expect-error
    offer.jobName = offer.jobname
    //@ts-expect-error
    delete offer["jobname"]

    //@ts-expect-error
    offer.userId = offer.userid
    //@ts-expect-error
    delete offer["userid"]
}


const columns = "id,categoryName,detailsSchedule,userId,offerType,jobName,timestamp,workPreference"

export const GetOffersThunk = createAsyncThunk(
    'manageOffersData/GetOffersThunk',
    async ({ type, page, limit, bringTotal }: ExtraDataOffer) => {
      try { 
        const typeOffer: GimGimmer = type === "Task" ? "Gim" : "Gimmer"
        const params = {
          offerType: typeOffer, 
          limit, 
          page,
          bringTotal,
          columns
        }

        const res = await getOffers(params)

        res.results = res.results.map((offer: Offer) => {
            changeNameToUppercase(offer)
            addRemainingFieldsOffer(offer)
            return offer
        })
        return {
          results: res.results as Offer[],
          LastEvaluatedKey: res.LastEvaluatedKey,
          type,
          page,
          limit,
          bringTotal,
          total: res.total
        }
      } catch (error) {
        console.log("error", error)
        return {
          results: [] as Offer[],
          type,
          page,
          limit,
          bringTotal,
          total: 0
        }
      }
    }
)

export const GetOfferByUsersThunk = createAsyncThunk(
  'manageOffersData/GetOfferByUsersThunk',
  async ({ stringToSearch, limit, bringTotal, page }: { page: number, stringToSearch: string, limit: number, bringTotal: boolean }) => {
    try { 
      const res = await getOffersByUsers({ stringToSearch, limit, bringTotal: true })
      res.results = res.results.map((offer: Offer) => {
        addRemainingFieldsOffer(offer)
        return offer
      })
      return {
        results: res.results as Offer[],
        LastEvaluatedKey: res.LastEvaluatedKey ?? "",
        page,
        limit,
        bringTotal,
        total: res.total,
        stringToSearch
      }
    } catch (error) {
      console.log("error", error)
      return {
        results: [] as Offer[],
        page,
        limit,
        bringTotal,
        total: 0,
        stringToSearch
      }
    }
  }
)

export const GetOfferByIdSearchBarThunk = createAsyncThunk(
  'manageOffersData/GetOfferByIdSearchBarThunk',
  async ({ id }: { id: string }) => {
    try { 
      const res = await getOffer(id)
      return {
        offer: res.offer as Offer
      }
    } catch (error) {
      console.log("error", error)
      return {
        offer: {} as Offer
      }
    }
  }
)

export const GetOfferByIdThunk = createAsyncThunk(
  'manageOffersData/GetOfferByIdThunk',
  async ({ id }: { id: string }, { getState }: any) => {
    try { 
      const { offer } = await getOffer(id)
      const userId = getState().auth.user.id

      offer.type = userId === offer.gimId ? "Job" : "Task"
      return {
        offer: offer as Offer
      }
    } catch (error) {
      console.log("error", error)
      return {
        offer: {} as Offer
      }
    }
  }
)

export const numberOffersByPage: number[] = [ 10, 25, 50, 100 ]

const initialState = {
    stringToSearch: "",
    total: 0,
    indexTab: 1,
    limit: numberOffersByPage[0],
    page: 1,
    type: "Task" as TypeOffers,
    offersIds: [] as string[],
    offersToShow: [] as Offer[],
    LastEvaluatedKey: "",
    loadingContainer: true,
    orderColumn: "",
    orderDirection: false,
    offer: {} as Offer,
    loadingIndividualPage: true
}

export const manageOffersDataSlice = createSlice({
  name: 'manageOffersData',
  initialState,
  reducers: {

  updateSeenOfferReducer: (state, action: PayloadAction<{ id: string }>) => {
        state.offersToShow = state.offersToShow.map((offer: Offer) => {
          if(offer.id === action.payload.id)
            offer.seen = true
          return offer
        })
        return state
    },
    removeDeletedOfferReducer: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      if(id === state.LastEvaluatedKey && state.offersIds.length > 1){
        const position = state.offersIds.indexOf(id)
        state.LastEvaluatedKey = state.offersIds[position-1]
      }
      state.offersIds = state.offersIds.filter((id: string) => id !== action.payload.id)
      state.offersToShow = state.offersToShow.filter((offer: Offer) => offer.id !== action.payload.id)
      state.total = state.total - 1
      return state
    },
    changeIndexTabReducer: (state, action: PayloadAction<{ indexTab: number }>) => {
      state.offersIds = []
      state.indexTab = action.payload.indexTab
      return state
    },
    orderOffersReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
      const { orderColumn, orderDirection  } = action.payload

      state.offersToShow = state.offersToShow.map((offer: Offer) => {
        const user = UserInfoCache.userNameCache.get(offer.userId)
        if(user?.name)
          offer.userName = user.name
        return offer
      })
      state.offersToShow = getOrderedState({ orderColumn, orderDirection, array: state.offersToShow })
      state.orderColumn = orderColumn
      state.orderDirection = orderDirection
      return state
    },
    resetDetailedOfferReducer: (state) => {
      state.offer = {} as Offer
      state.loadingIndividualPage = true
      return state
    },
    resetManageOffersReducer: () => {
      return initialState
    }
  },

  extraReducers: (builder) => {
    builder.addCase(GetOffersThunk.fulfilled, (state, action: PayloadAction<{ results: Offer[], LastEvaluatedKey?: string, type: TypeOffers, page: number, limit: number, bringTotal: boolean, total: number }>) => {
      const { results, type, page, LastEvaluatedKey, limit, bringTotal, total } = action.payload  

      let lastIndex = 0
      for(let i=0; i < results.length; i++){
          const skip = state.limit * (page-1)
          state.offersIds[skip+i] = results[i].id
          lastIndex = skip+i
      }

        state.offersIds = state.offersIds.slice(0,lastIndex+1)

        state.offersToShow = results
        state.type = type
        state.loadingContainer = false
        state.page = page
        state.LastEvaluatedKey = LastEvaluatedKey ?? ""
        state.limit = limit ?? 0
        if(bringTotal)
            state.total = total
        state.stringToSearch = ""
        return state
      }).addCase(GetOffersThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(GetOffersThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      })
      
      
      
      .addCase(GetOfferByIdSearchBarThunk.fulfilled, (state, action: PayloadAction<{ offer: Offer }>) => {
        const { offer } = action.payload
        if(offer.id){
          addRemainingFieldsOffer(offer)
          state.offersToShow = new Array(offer)
          state.offersIds = new Array(action.payload.offer.id)
          state.total = 1
        }else{
          state.offersToShow = []
          state.offersIds = []
          state.total = 0
        }
        state.loadingContainer = false
        state.stringToSearch = ""
        return state
      }).addCase(GetOfferByIdSearchBarThunk.pending, (state, action: any) => {
          state.loadingContainer = true
          return state     
      }).addCase(GetOfferByIdSearchBarThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingContainer = false
          return failState
      })


      .addCase(GetOfferByIdThunk.fulfilled, (state, action: PayloadAction<{ offer: Offer }>) => {
        const { offer } = action.payload
        if(offer.id){
          addRemainingFieldsOffer(offer)
          state.offer = offer
        }
        state.loadingIndividualPage = false
        return state

      })
      .addCase(GetOfferByIdThunk.pending, (state, action: any) => {
        state.loadingIndividualPage = true
          return state     
      }).addCase(GetOfferByIdThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingIndividualPage = false
          return failState
      })
      
      
      .addCase(GetOfferByUsersThunk.fulfilled, (state, action: PayloadAction<{ results: Offer[], LastEvaluatedKey?: string, page: number, limit: number, bringTotal: boolean, total: number, stringToSearch: string }>) => {
          const { results, page, LastEvaluatedKey, bringTotal, total, stringToSearch } = action.payload
          state.offersIds = results.map((offer: Offer) => offer.id)
          state.offersToShow = results
          state.loadingContainer = false
          state.page = page
          state.LastEvaluatedKey = LastEvaluatedKey ?? ""
          if(bringTotal)
            state.total = total
          state.total = 0
          state.loadingContainer = false
          state.stringToSearch = stringToSearch
          return state
      }).addCase(GetOfferByUsersThunk.pending, (state, action: any) => {
          state.loadingContainer = true
          return state     
      }).addCase(GetOfferByUsersThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingContainer = false
          return failState
      })
  },

});

// Action creators are generated for each case reducer function
export const {  
  updateSeenOfferReducer, 
  removeDeletedOfferReducer,
  changeIndexTabReducer,
  resetManageOffersReducer,
  orderOffersReducer,
  resetDetailedOfferReducer
} = manageOffersDataSlice.actions

export default manageOffersDataSlice.reducer