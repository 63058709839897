import { useEffect, useState } from "react"
import "./HomeScreen.css"
import { getOffersPublic } from "../../utils/requestsUtils/functionsRequests/offers/offersFunctions"
import ContainerOfferCards from "../../components/Home/ContainerOfferCards/ContainerOfferCards"


type HomScreenProps = {
  isPrivate: boolean
}

const HomeScreen = ({ isPrivate }: HomScreenProps ) => {

    const [idsOffers, setIdsOffers] = useState<string[]>([])

    useEffect(() => {
      
      getOffersPublic({ limit: 20, page: 1, columns: "id", offerType: "Gimmer" })
      .then(({ results }: { results: string[] }) => {
        console.log("ids", results)
        setIdsOffers(results)
      }).catch(() => {

      })
      
    }, [])
    

    return (
    <div className="container-home-screen">
        { // loadingAuthUser && <LoadingSpinner fullPage={true} />
         }
        <ContainerOfferCards ids={idsOffers} />
    </div>
  )
}

export default HomeScreen