import React, { useEffect } from 'react'
import HeaderGeneralInformation from '../../../../components/GeneralInformation/HeaderGeneralInformation/HeaderGeneralInformation'
import GeneralInformationModals from '../../../../components/GeneralInformation/GeneralInformationModals/GeneralInformationModals'
import { getGeneralInformationChunk } from '../../../../redux/slices/components/GeneralInformation/GeneralInformationSlice'
import { useAppDispatch } from '../../../../redux/store/store'
import RowsGeneralInformation from '../../../../components/GeneralInformation/RowsGeneralInformation/RowsGeneralInformation'

const GeneralInformationScreen = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch( getGeneralInformationChunk() )
    }, [])
    

    return (
        <div className="flex flex-d-column height-100">
            <HeaderGeneralInformation />
            <RowsGeneralInformation />
            <GeneralInformationModals />
        </div>
    )
}

export default GeneralInformationScreen