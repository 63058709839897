import React, { useState } from 'react'
import "./InputList.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTranslation } from 'react-i18next'

type InputListProps = {
    text?: string
    width: string
    height?: string
    error?: string
    fontSize?: string
    children: React.ReactNode
}

const InputList = ({
    text,
    width,
    height = "4rem",
    error,
    fontSize,
    children
}: InputListProps) => {

    const { t } = useTranslation()

    const [statusInput, setStatusInput] = useState(false)

    const clickInput = () => {
        setStatusInput(!statusInput)
    }

    return (
        <div className="p-relative" style={{ width }}>
            <div 
                onClick={clickInput}
                className={`flex flex-jc-sb flex-ai-center input-list pointer ${statusInput ? "input-list-focused" : ""}`}
                style={{ height }}
            >

                <div className="flex flex-1">
                    <p className={fontSize ?? "fs-14"}>
                        { text || `-- ${t("General:label_250")}` }
                    </p>
                </div>
                <div className={ statusInput ? "color-submit" : "rotate-180" }>
                    <FontAwesomeIcon icon={solid("caret-up")} />
                </div>
                
                {
                statusInput &&
                <div className="con-input-list" style={{ top: height }}>
                    {children}
                </div>
                }

            </div>
            <p className="error-input-list">{error  || ""}</p>
        </div>
    )
}

export default InputList