import "./ConfirmButton.css"

type ConfirmButtonProps = {
    text: string,
    width?: string
}

const ConfirmButton = ({ text, width }:ConfirmButtonProps) => {
  return (
    <button type="submit" className="confirm-btn-modal pointer fs-16" style={{ "width": width ? width : "14rem" }}>
        { text }
    </button>
  )
}

export default ConfirmButton