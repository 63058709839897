import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import { GeneralInformationInfo } from '../../../redux/slices/components/GeneralInformation/GeneralInformationSlice'
import RowGeneralInformation from './RowGeneralInformation/RowGeneralInformation'
import LoadingSpinner from '../../LoadingSpinner'

const RowsGeneralInformation = () => {

    const informations = useSelector((state: RootState) => state.generalInformation.informations)
    const loadingContainer = useSelector((state: RootState) => state.generalInformation.loadingContainer)

    return (
        <div>
            {
                informations.map((information: GeneralInformationInfo, index: number) => {
                    return(
                        <RowGeneralInformation 
                            key={information.countryName}
                            index={index}
                            countryName={information.countryName}
                            gimworkFee={information.gimworkFee}
                            countryTax={information.countryTax}
                        />
                    )
                })
            }
            { loadingContainer && <LoadingSpinner fullPage={false} backgroundColor="#fff" /> }
        </div>
    )
}

export default RowsGeneralInformation