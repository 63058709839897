import React, { useEffect } from 'react'
import { getOfferPublic } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import "./OfferCard.css"

type OfferCardsProps = {
    id: string
}

const OfferCard = ({ id }: OfferCardsProps) => {

    useEffect(() => {
      
        const getData = () => {
            getOfferPublic(id)
            .then((res) => {
                console.log("offer", res)
            }).catch(() => {

            })
        }
    
        getData()

    }, [])
    

    return (
        <div className="offer-card flex flex-d-column">
            <div className="header-offer-card">

            </div>
            <div className="body-offer-card">

            </div>
            <div className="footer-offer-card">

            </div>
        </div>
    )
}

export default React.memo( OfferCard )