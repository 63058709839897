import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import FormAddGeneralInformationModal from './FormAddGeneralInformationModal/FormAddGeneralInformationModal';
import { resetModalsValueFromGeneralInformationState } from '../../../../redux/slices/components/GeneralInformation/GeneralInformationSlice';

const AddGeneralInformationModal = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();
  
    const closeModal = useCallback(
        () => {
            dispatch(resetModalsValueFromGeneralInformationState())
        }
        ,
        [],
    )
    
    return (
      <div className="blank-wrapper-modals flex flex-d-column  p-relative" >
          <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
              <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
          </button>
          <div className="flex flex-jc-sb flex-ai-center pr-10">
              <TitleModal title={t("General:label_123")} />
          </div>
          <FormAddGeneralInformationModal closeModal={closeModal} />
      </div>
    )
}

export default AddGeneralInformationModal