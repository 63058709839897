import React, { useLayoutEffect, useState } from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { useSelector } from 'react-redux';
import { User, getProfilePicture, login, setLoadingAuth } from '../../redux/slices/authSlice';
import NavBar from '../../components/NavBarComponents/NavBar/NavBar';
import { getMyUser } from '../../utils/requestsUtils/functionsRequests/user/userFunctions';
import { useTranslation } from 'react-i18next';
import LogoutButton from '../../components/SideBar/LogoutButton/LogoutButton';
import PercentageBarWrapper from '../../components/SignUp/UIComponents/PercentageBarWrapper/PercentageBarWrapper';
import PublicNavBar from '../../components/NavBarComponents/PublicNavBar/PublicNavBar';
import moment from 'moment';
import { getUserCurrencyBrowser, getUserLanguageBrowser, localStorageCurrency, localStorageLanguage } from '../../utils/preferences/preferences';

type PrivateRouteProps = {
    children: React.ReactNode
    percentageBar?: number
}

const PrivateRoute = ({ children, percentageBar }: PrivateRouteProps) => {

    const { i18n } = useTranslation()
    
    const dispatch = useAppDispatch()
    
    const navigate = useNavigate()

    const isLogged = useSelector((state: RootState) => state.auth.isLogged);
    const loading = useSelector((state: RootState) => state.auth.loading);
    const firstName = useSelector((state: RootState) => state.auth.user.firstName);


    useLayoutEffect(() => {
        if(isLogged){
            if(loading)
                dispatch(setLoadingAuth(false))
            return
        }
       
        const getData = async() => {
            getMyUser()
            .then(({ user }: { user: User }) => {

                const publicLanguage = getUserLanguageBrowser()
                const publicCurrency = getUserCurrencyBrowser()
    
                const currentLanguage = user.preferences?.language ?? publicLanguage
                const currentCurrency = user.preferences?.currency?.currCode ?? publicCurrency

                i18n.changeLanguage(currentLanguage)
                moment.locale(currentLanguage); 
                localStorage.setItem(localStorageLanguage, currentLanguage)
                localStorage.setItem(localStorageCurrency, currentCurrency)

                if(user.firstName === "firstName"){
                    navigate("/name-federative")
                    dispatch(login({ isLogged: true, user}))
                }else{
                    dispatch(getProfilePicture(user.id))
                    dispatch(login({ isLogged: true, user }))
                }
                dispatch(setLoadingAuth(false))
            })
            .catch(() => {
                navigate("/error-auth");
                dispatch(setLoadingAuth(false))
            }).finally(() => {
                
            })
        }

        getData()
        
    }, [])

    if(loading)
        return <LoadingSpinner fullPage={true} />
    else if(!isLogged){
        //console.log("hi");
        //navigate("/login")
        return <Navigate to="/login" replace />
        
    }else if( percentageBar && percentageBar > -1 ){
        return (
            <div style={{ height: "100vh" }}>

                <PublicNavBar />

                <PercentageBarWrapper percentageBar={percentageBar} >
                    {children}
                </PercentageBarWrapper>
            </div>
        )
    }else{
        return(
            <>
                <NavBar />

                <div className="height-flex-100">
                    { children }
                </div>


            </>
        )
    }

};



export default React.memo( PrivateRoute );
